import React from 'react';
import './Loading.css';
import PropTypes from 'prop-types';
import spinner from '../../assets/Spinner.gif';

/**
 * @param {*} props - the props of the component
 * @param {string | JSX.Element} props.headerText - the content to describe the loading
 * @returns {JSX.Element} Loading component
 */
export default function Loading({ headerText }) {
  return (
    <div className='loading v-container-vh'>
      <img
        className='spinner'
        src={ spinner }
        alt={ headerText }
      />
      <header>
        {headerText}
      </header>
    </div>
  );
}

Loading.propTypes = {
  headerText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
};
