import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  UpdateMiniAppAdvanceContext,
  UpdateMiniAppAuthHandlerContext,
  UpdateMiniAppChangeCardContext,
  UpdateMiniAppPaymentContext,
  UpdateMiniAppShareContext,
} from '../services/context/MiniAppContext.js';
import MiniAppMessageTypes from '../services/Constants/MiniAppMessageTypes.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function MiniAppController({ slot }) {
  const inMiniApp = window.my;
  const [authCodeHandler, setAuthCodeHandler] = useState(message => message);
  const [changeCardHandler, setChangeCardHandler] = useState(message => message);
  const [paymentHandler, setPaymentHandler] = useState(message => message);
  const [shareHandler, setShareHandler] = useState(message => message);
  const [advanceHandler, setAdvanceHandler] = useState(message => message);

  if (inMiniApp) {
    useEffect(() => {
      window.my.onMessage = message => {
        switch (message.messageType) {
          case MiniAppMessageTypes.auth: {
            authCodeHandler(message);
            break;
          }

          case MiniAppMessageTypes.advance: {
            advanceHandler(message);
            break;
          }

          case MiniAppMessageTypes.payment: {
            paymentHandler(message);
            break;
          }

          case MiniAppMessageTypes.share: {
            shareHandler(message);
            break;
          }

          case MiniAppMessageTypes.changeCard: {
            changeCardHandler(message);
            break;
          }

          default: {
            break;
          }
        }
      };
    }, [
      authCodeHandler,
      changeCardHandler,
      paymentHandler,
      shareHandler,
      advanceHandler,
    ]);
  }

  return (
    <UpdateMiniAppPaymentContext.Provider value={ setPaymentHandler }>
      <UpdateMiniAppAuthHandlerContext.Provider value={ setAuthCodeHandler }>
        <UpdateMiniAppShareContext.Provider value={ setShareHandler }>
          <UpdateMiniAppChangeCardContext.Provider value={ setChangeCardHandler }>
            <UpdateMiniAppAdvanceContext.Provider value={ setAdvanceHandler }>
              {slot}
            </UpdateMiniAppAdvanceContext.Provider>
          </UpdateMiniAppChangeCardContext.Provider>
        </UpdateMiniAppShareContext.Provider>
      </UpdateMiniAppAuthHandlerContext.Provider>
    </UpdateMiniAppPaymentContext.Provider>
  );
}

MiniAppController.propTypes = { slot: PropTypes.element };
