import { createContext, Context, Dispatch, SetStateAction } from 'react';
import { UserDetails } from '../Constants/ObjectDefinitions.js';

/**
 * @type {Context<UserDetails>}
 */
export const UserDetailsContext = createContext(undefined);

/**
 * @type {Context<Dispatch<SetStateAction<UserDetails>>>}
 */
export const UpdateUserDetailsContext = createContext(def => def);
