import React, { useContext } from 'react';
import './PayAdvanceDetails.css';
import KeyValueList from '../KeyValueList/KeyValueList.jsx';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import { CurrentCouponContext, CurrentCouponLoanDetailsContext } from '../../services/context/CurrentCouponContext.js';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import {
  PaymentAmountContext,
  PaymentTouchedContext,
  UpdatePaymentTouchedContext,
} from '../../services/context/PaymentAmountContext.js';

/**
 * @returns {JSX.Element} PayAdvanceDetails Component
 */
export default function PayAdvanceDetails() {
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const paymentAmount = useContext(PaymentAmountContext);
  const touched = useContext(PaymentTouchedContext);
  const setTouched = useContext(UpdatePaymentTouchedContext);
  const navigateTo = useNavigate();
  const currentAdvance = useContext(CurrentCouponContext) ?? {};
  const loanDetails = useContext(CurrentCouponLoanDetailsContext);
  /**
   * @param {Date} date - date to format
   * @returns {string} formatted date
   */
  const dateStringFormatter = date => `${date.toLocaleString('default', { day: 'numeric' })} ${date.toLocaleString('default', {
    year: 'numeric',
    month: 'short',
  })}`;

  const expiryDate = new Date(currentAdvance.expiryDate);
  const expiryText = dateStringFormatter(expiryDate);
  const purchaseDate = new Date(currentAdvance.purchaseDate);
  const totalOutstanding = MoneyFormatter.get(loanDetails.outstanding);
  const { loanMaturity } = currentAdvance.advanceHistory.advanceDetail;
  const paymentDueDate = new Date(purchaseDate);
  paymentDueDate.setDate(paymentDueDate.getDate() + loanMaturity);
  const paymentDueText = dateStringFormatter(paymentDueDate);

  const analyticsName = useContext(AnalyticsPageContext);
  const amount = Number(useContext(PaymentAmountContext));
  const onClickPay = () => {
    setTouched(true);
    if (amount < 2) return;
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.interaction],
      link_id: `${pagePrefix}: my vouchers: pay now`,
      event_label: 'voucher advance: settle full amount',
      event_value: amount === totalOutstanding.amount,
    });
    navigateTo(ROUTES.SUMMARY);
  };

  return (
    <section className='pay-advance-details v-container'>
      <KeyValueList object={
        {
          Outstanding: MoneyFormatter.format(totalOutstanding),
          'Payment due': paymentDueText,
          'Expiry date': expiryText,
          'Payment method': 'Advance',
          'Voucher type': currentAdvance?.name,
        }
      }
      />
      <button
        className='main-button'
        onClick={ onClickPay }
        disabled={ touched && paymentAmount < 2 }
      >Proceed to payment</button>
    </section>
  );
}
