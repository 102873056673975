import { createContext } from 'react';

export const UpdateMiniAppAuthHandlerContext = createContext(def => def);

export const UpdateMiniAppAdvanceContext = createContext(def => def);

export const UpdateMiniAppPaymentContext = createContext(def => def);

export const UpdateMiniAppShareContext = createContext(def => def);

export const UpdateMiniAppChangeCardContext = createContext(def => def);
