import React, { useContext, useEffect, useState } from 'react';
import './SnackBar.css';
import { SnackBarContext, SnackBarDuration } from '../../services/context/SnackBarContext.js';
import CloseIcon from '../../assets/CloseIcon.jsx';

/**
 * @returns {JSX.Element} SnackBar Component
 */
export default function SnackBar() {
  const [hidden, setHidden] = useState(true);
  const { icon, body } = useContext(SnackBarContext) ?? {};

  useEffect(() => {
    if ((icon || body)) {
      const time = setTimeout(() => setHidden(false), 50);
      const timeout = setTimeout(() => setHidden(true), SnackBarDuration + 50);
      return () => {
        clearTimeout(timeout);
        clearTimeout(time);
      };
    }
  }, [
    icon,
    body,
  ]);

  if (!(icon || body)) {
    return null;
  }

  return (
    <div className={ `snack-bar container-vh${hidden ? ' animating' : ''}` }>
      {icon}
      {body}
      <button
        className='container-vh'
        onClick={ () => setHidden(true) }
      >
        <CloseIcon />
      </button>
    </div>
  );
}
