import React, { useContext } from 'react';
import './StubCouponBottom.css';
import { MoneyFormatterContext } from '../../../services/context/MoneyFormatterContext.js';
import PropTypeShapes from '../../../services/Constants/PropTypeShapes.jsx';
import { Gift } from '../../../services/Constants/ObjectDefinitions.js';
import PropTypes from 'prop-types';

/**
 *
 * @param {object} props - the props of the component
 * @param {Gift} props.gift - the gift associated with the coupon
 * @param {boolean} props.isFailure - whether successful or not
 * @returns {JSX.Element} StubCouponBottom Component
 */
export default function StubCouponBottom({ gift, isFailure }) {
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const redemptionText = gift?.voucher?.redemption.type;
  return (
    <header className={ `stub-bottom container-v${gift ? '' : ' empty skeleton-loader'}` }>
      <div className='v-container'>
        <h3>
          {gift && MoneyFormatter.format(gift.value)}
        </h3>

        <p className='extra-info'>
          {redemptionText}
        </p>
      </div>

      <p>
        {
          gift && !isFailure
          && 'Your voucher will be available in 2 minutes'
        }
        {
          gift && isFailure
          && gift.voucher.reason
        }
      </p>
    </header>
  );
}

StubCouponBottom.propTypes = {
  gift: PropTypeShapes.gift,
  isFailure: PropTypes.bool,
};
