import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RocketContext, UpdateRocketContext } from '../services/context/RocketContext.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The SuccessController Component
 */
export default function RocketController({ slot }) {
  const [success, setSuccess] = useState(null);

  return (
    <RocketContext.Provider value={ success }>
      <UpdateRocketContext.Provider value={ setSuccess }>
        { slot }
      </UpdateRocketContext.Provider>
    </RocketContext.Provider>
  );
}

RocketController.propTypes = { slot: PropTypes.element };
