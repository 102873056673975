import React, { useContext } from 'react';
import './MaintenanceMessage.css';
import { ConfigContext } from '../../services/context/ConfigContext.js';
import { MaintenanceMessageContext } from '../../services/context/MaintenanceContext.js';

/**
 * @returns {JSX.Element} MaintenanceMessage Component
 */
export default function MaintenanceMessage() {
  const config = useContext(ConfigContext);
  const maintenanceMessage = useContext(MaintenanceMessageContext);
  return (
    <div className='maintenance-message v-container'>
      <h3>{'We\'re so sorry for the inconvenience'}</h3>
      <p>
        {maintenanceMessage ?? config?.frontend.general.maintenanceWarning.message}
      </p>
    </div>
  );
}
