import React from 'react';
import './EmptyIconButton.css';
import PropTypes from 'prop-types';

/**
 *
 * @param {object} props - the props of the component
 * @param {JSX.Element} props.icon - the SVG icon
 * @param {string} props.title - the title text
 * @param {string} props.buttonText - text to display on button
 * @param {Function} props.onClick - onclick handler
 * @returns {JSX.Element} EmptyIconButton component
 */
export default function EmptyIconButton({ icon, title, buttonText, onClick }) {
  return (
    <section className='empty-icon-button container-vh'>
      {icon}
      <section className='v-container'>
        <h3>{title}</h3>
        <button
          className='main-button'
          onClick={ onClick }
        >
          {buttonText}
        </button>
      </section>
    </section>
  );
}

EmptyIconButton.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};
