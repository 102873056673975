const Successful = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
};
const ServerError = { INTERNAL_SERVER_ERROR: 500 };

const Redirection = { NOT_MODIFIED: 304 };

const ClientError = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  FAILED_DEPENDENCY: 424,
};

export default {
  ...Successful,
  ...ClientError,
  ...ServerError,
  ...Redirection,
};
