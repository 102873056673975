import { useState } from 'react';

/**
 * @callback onSlide
 * @param {number} distance the distance slid
 */

/**
 *
 * @param {onSlide} onSlide handler to call on successful slide
 * @param {boolean} vertical whether to track vertical or horizontal sliding
 * @param {number} minSwipe the minimum pixels to be considered a successful swipe
 * @returns {{
 * distance: number,
 * onTouchEnd: Function,
 * onTouchMove: Function,
 * onTouchStart: Function,
 * touching: boolean}
 * } handlers to apply to the element to track as well as useful information about the sliding
 */
export default function useSliding(onSlide, vertical = true, minSwipe = 50) {
  const [touching, setTouching] = useState(false);
  const [touchStartPrimary, setTouchStartPrimary] = useState(/** @type {number} */null);
  const [touchStartSecondary, setTouchStartSecondary] = useState(/** @type {number} */null);
  const [touchEndPrimary, setTouchEndPrimary] = useState(/** @type {number} */null);
  const [touchEndSecondary, setTouchEndSecondary] = useState(/** @type {number} */null);
  const primaryDistance = touchEndPrimary ? touchEndPrimary - touchStartPrimary : 0;
  const secondaryDistance = touchEndSecondary ? touchEndSecondary - touchStartSecondary : 0;

  const getPosition = isPrimary => {
    if (isPrimary) return vertical ? 'clientY' : 'clientX';
    return vertical ? 'clientX' : 'clientY';
  };

  const onTouchStart = e => {
    e.stopPropagation();
    setTouching(true);
    setTouchEndPrimary(null);
    setTouchStartPrimary(e.targetTouches[0][getPosition(true)]);
    setTouchStartSecondary(e.targetTouches[0][getPosition(false)]);
  };

  const onTouchMove = e => {
    e.stopPropagation();
    setTouchEndPrimary(e.targetTouches[0][getPosition(true)]);
    setTouchEndSecondary(e.targetTouches[0][getPosition(false)]);
  };

  const onTouchEnd = e => {
    e.stopPropagation();
    setTouching(false);
    if (!(touchStartPrimary && touchEndPrimary)) return;
    if (Math.abs(primaryDistance) < minSwipe) return;
    if (Math.abs(secondaryDistance) > Math.abs(primaryDistance)) return;
    onSlide(primaryDistance);
  };

  return {
    distance: (Math.abs(secondaryDistance) > Math.abs(primaryDistance)) ? 0 : primaryDistance,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    touching,
  };
}
