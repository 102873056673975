const termUnits = { days: 'days' };

export const stringToDate = termString => {
  const today = new Date(Date.now());
  const [value, unit] = termString.split(' ');
  const checkDate = new Date(today);
  if (unit.toLowerCase() !== termUnits.days) throw new Error(`Unsupported term unit: ${unit}`);
  checkDate.setDate(checkDate.getDate() + (Number(value)));

  return checkDate;
};

export const stringToDays = termString => {
  const [value, unit] = termString.split(' ');
  if (unit.toLowerCase() !== termUnits.days) throw new Error(`Unsupported term unit: ${unit}`);
  return Number(value);
};
