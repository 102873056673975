import React, { useContext } from 'react';
import './PayAdvanceInputs.css';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import MoneyInput from '../MoneyInput/MoneyInput.jsx';
import ToggleButton from '../ToggleButton/ToggleButton.jsx';
import { CurrentCouponLoanDetailsContext } from '../../services/context/CurrentCouponContext.js';
import {
  PaymentAmountContext,
  PaymentTouchedContext,
  UpdatePaymentAmountContext,
  UpdatePaymentTouchedContext,
} from '../../services/context/PaymentAmountContext.js';
import TopBack from '../TopBack/TopBack.jsx';

/**
 * @returns {JSX.Element} PayAdvanceInputs Component
 */
export default function PayAdvanceInputs() {
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const touched = useContext(PaymentTouchedContext);
  const setTouched = useContext(UpdatePaymentTouchedContext);
  const value = useContext(PaymentAmountContext);
  const setValue = useContext(UpdatePaymentAmountContext);
  const loanDetails = useContext(CurrentCouponLoanDetailsContext);
  const totalOutstanding = MoneyFormatter.get(loanDetails.outstanding);

  return (
    <div className='pay-advance-inputs'>
      <TopBack />
      <h3>You currently owe: {MoneyFormatter.format(totalOutstanding)}</h3>
      <p className='extra-info'>How much would you like to pay? This amount includes late payment fees if relevant.</p>
      <MoneyInput
        touched={ touched }
        value={ value }
        setTouched={ setTouched }
        setValue={ setValue }
        maxValue={ totalOutstanding.amount }
        minValue={ 2 }
      />
      <section className='max container-v'>
        <h4>Settle full amount</h4>
        <ToggleButton
          isChecked={ Number(value) >= totalOutstanding.amount }
          onChange={
            () => {
              setTouched(true);
              if (Number(value) >= totalOutstanding.amount) {
                setValue('');
              } else {
                setValue(totalOutstanding.amount.toFixed(2));
              }
            }
          }
        />
      </section>
    </div>
  );
}
