import React from 'react';
import './AdvanceDisclaimer.css';
import MiniAppMessageTypes from '../../services/Constants/MiniAppMessageTypes.js';

/**
 * @returns {JSX.Element} AdvanceDisclaimer Component
 */
export default function AdvanceDisclaimer() {
  return (
    <div className='advance-disclaimer v-container-h'>
      <h4>Please note:</h4>
      <p>To take an advance, please use a card that does not expire within the next 3 months.</p>
      <p>By tapping “Get advance” you agree to the Vouchers
        <button
          onClick={ () => window.my.postMessage({ messageType: MiniAppMessageTypes.termsAndCond }, '*') }
        ><a>Terms & Conditions</a>.</button>
      </p>
    </div>
  );
}
