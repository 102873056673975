import React from 'react';
import NavItem from '../NavItem.jsx';
import VoucherIcon from '../../../assets/VoucherIcon.jsx';
import ROUTES from '../../../services/Constants/GlobalRoutes.jsx';

/**
 * @returns {JSX.Element} NavMyVouchers Component
 */
export default function NavMyVouchers() {
  return (
    <NavItem
      icon={ <VoucherIcon /> }
      route={ ROUTES.MY_VOUCHERS }
      name='My Vouchers'
    />
  );
}
