import { startTransaction } from '@sentry/react';

export const withSentrySpan = async (transaction, configObj, callback) => {
  const childSpan = transaction.startChild({ ...configObj });
  try {
    return await callback(); // callback, ambiguous args
  } finally {
    childSpan.finish();
    transaction.finish();
  }
};

/**
 * @typedef {object} SentryTransaction
 * @property {string} op The operation identifier.
 * @property {string} description The description.
 */

/**
 * @typedef {object} Step
 * @property {SentryTransaction} transaction The step transaction config.
 * @property {function(): Promise<any>} worker The step worker.
 */

/**
 * The returned function of useWithSentry accepts an object with the following properties:
 *
 * @param {object} props - Properties.
 * @param {SentryTransaction} props.sentryTransaction - Transaction object.
 * @param {function(): any} [props.successCallback] - A callback that executes after the transaction is complete.
 * @param {function(any): any} [props.exceptionCallback] - A callback that executes after a transaction exception is thrown.
 * @param {Step[]} props.steps - Array of steps to execute in order.
 */
export const withSentryTransaction = async ({
  sentryTransaction,
  successCallback,
  exceptionCallback,
  steps,
}) => {
  const transaction = startTransaction(sentryTransaction);

  try {
    for (const step of steps) {
      // Need to execute steps in order, so cant Promise.all
      // eslint-disable-next-line no-await-in-loop
      await withSentrySpan(
        transaction,
        step.transaction,
        step.worker,
      );
    }

    successCallback?.();
  } catch (error) {
    if (exceptionCallback) exceptionCallback(error);
    else throw error;
  } finally {
    transaction.finish();
  }
};
