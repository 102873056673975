import { createContext, Context, Dispatch, SetStateAction } from 'react';
import { Gift } from '../Constants/ObjectDefinitions.js';

/**
 * @type {Context<Gift>}
 */
export const CurrentCouponContext = createContext(undefined);

/**
 * @type {Context<{outstanding: number, fees: number, principal: number}>}
 */
export const CurrentCouponLoanDetailsContext = createContext(undefined);

/**
 * @type {Context<Dispatch<SetStateAction<Gift>>>}
 */
export const UpdateCurrentCouponContext = createContext(def => def);

/**
 * @type {Context<Dispatch<SetStateAction<{outstanding: number, fees: number, principal: number}>>>}
 */
export const UpdateCurrentCouponLoanDetailsContext = createContext(undefined);
