import React, { useContext } from 'react';
import './PaymentSummaryHeader.css';
import KeyValueList from '../KeyValueList/KeyValueList.jsx';
import { CurrentCouponLoanDetailsContext } from '../../services/context/CurrentCouponContext.js';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import TopBack from '../TopBack/TopBack.jsx';
import { AdvanceContext } from '../../services/context/AdvanceContext.js';

/**
 * @returns {JSX.Element} PaymentSummaryHeader Component
 */
export default function PaymentSummaryHeader() {
  const advance = useContext(AdvanceContext);
  const advanceDetails = useContext(CurrentCouponLoanDetailsContext) ?? {};
  const MoneyFormatter = useContext(MoneyFormatterContext);

  const paymentDate = advance?.date ?? new Date(Date.now());

  const dateText = `${paymentDate.toLocaleString('default', { day: 'numeric' })} ${paymentDate.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  })}`;

  const principalOutstanding = MoneyFormatter.get(advanceDetails.principal);
  const advanceFees = MoneyFormatter.get(advanceDetails.fees);

  return (
    <header className='payment-summary-header v-container'>
      <TopBack />
      <h3>Summary</h3>
      <div className='wrapper'>
        <KeyValueList object={
          {
            ...(advance && { 'Payment method': 'Advance' }),
            'Payment date': dateText,
            'Voucher amount': MoneyFormatter.format(principalOutstanding),
            ...(Number(advanceFees.amount) && { 'Additional fees': MoneyFormatter.format(advanceFees) }),
          }
        }
        />
      </div>
    </header>
  );
}
