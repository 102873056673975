import React from 'react';
import './DualButtons.css';
import PropTypes from 'prop-types';

/**
 * @param {object} props - the props of the component
 * @param {Function} props.primaryOnClick - onClick function for primary button
 * @param {JSX.Element | string} props.primaryText - component to display inside primary button
 * @param {boolean} props.primaryDisabled - whether the primary button is disabled
 * @param {boolean} props.primaryPayment - whether the primary button is a payment button (for styling purposes)
 * @param {Function} props.secondaryOnClick - onClick function for secondary button
 * @param {JSX.Element | string} props.secondaryText - component to display inside secondary button
 * @param {boolean} props.secondaryDisabled - whether the secondary button is disabled
 * @returns {JSX.Element} ManagePaymentButtons Component
 */
export default function DualButtons({
  primaryOnClick,
  primaryText,
  primaryDisabled,
  primaryPayment,
  secondaryOnClick,
  secondaryText,
  secondaryDisabled,
}) {
  return (
    <section className='dual-buttons v-container'>
      <div className='wrapper container'>
        <button
          className='tertiary-button'
          onClick={ secondaryOnClick }
          disabled={ secondaryDisabled }
        >
          {secondaryText}
        </button>
        <button
          className={ `main-button${primaryPayment ? ' payment' : ''}` }
          onClick={ primaryOnClick }
          disabled={ primaryDisabled }
        >
          {primaryText}
        </button>
      </div>
    </section>
  );
}

DualButtons.propTypes = {
  primaryOnClick: PropTypes.func,
  primaryPayment: PropTypes.bool,
  primaryText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  primaryDisabled: PropTypes.bool,
  secondaryOnClick: PropTypes.func,
  secondaryText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  secondaryDisabled: PropTypes.bool,
};
