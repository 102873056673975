import { shuffle } from './Shuffle.js';
import { useContext } from 'react';
import { MoneyFormatterContext } from './context/MoneyFormatterContext.js';
import StockStatus from './Constants/StockStatus.js';
import { Category, Voucher } from './Constants/ObjectDefinitions.js';

/**
 *
 * @param {Category[]} categories - all the available categories
 * @param {Voucher[]} cart - user's current cart
 * @returns {Voucher[]} - vouchers to upsell to the user
 */
export const getCrossSellVouchers = (categories, cart) => {
  const MoneyFormatter = useContext(MoneyFormatterContext);
  if (!categories) {
    return [];
  }

  const isVoucherInCart = voucher => cart.map(cartItem => cartItem.id).includes(voucher.id);

  const isVendorInCart = vendor => vendor?.vouchers?.find(voucher => isVoucherInCart(voucher));

  // currently not used as a business logic filter
  // const isCategoryInCart = category => category.vendors?.find(vendor => isVendorInCart(vendor));

  const isVoucherViable = voucher => {
    const cartCost = cart.reduce((accumulator, cartItem) => accumulator + MoneyFormatter.getValue(cartItem.cost).amount, 0);
    return voucher.stock.status === StockStatus.inStock && cartCost >= MoneyFormatter.getValue(voucher.cost).amount && !isVoucherInCart(voucher);
  };

  const viableVendors = categories.map(category => category.vendors.filter(vendor => !isVendorInCart(vendor)));
  const viableVouchers = viableVendors.map(vendors => vendors.map(vendor => vendor.vouchers?.filter(voucher => isVoucherViable(voucher))));
  const maxNumVouchersToShow = 2;
  const vouchersToShow = [];
  while (vouchersToShow.length < maxNumVouchersToShow) {
    if (viableVouchers.length === 0) {
      break;
    }

    const nextCategory = viableVouchers.shift();
    if (!nextCategory || nextCategory.length === 0) {
      continue;
    }

    shuffle(nextCategory);
    viableVouchers.push(nextCategory);

    const nextVendor = nextCategory.shift();
    if (!nextVendor || nextVendor.length === 0) {
      continue;
    }

    shuffle(nextVendor);

    vouchersToShow.push(nextVendor.shift());
    nextCategory.push(nextVendor);
  }

  return vouchersToShow;
};
