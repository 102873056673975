import React, { useContext, useEffect } from 'react';
import './AdvanceInfo.css';
import SpaceMan from '../../../assets/SpaceMan.jsx';
import PropTypes from 'prop-types';
import Slider from '../../Slider/Slider.jsx';
import { AnalyticsPageContext } from '../../../services/context/AnalyticsPageContext.js';
import { defaultAnalyticsVariables, events } from '../../../services/Constants/Analytics.js';

/**
 *
 * @param {object} props - the props of the component
 * @param {Function} props.setShow - a function to hide itself onClickOutside
 * @returns {JSX.Element} AdvanceInfo Component
 */
export default function AdvanceInfo({ setShow }) {
  const analyticsName = useContext(AnalyticsPageContext);
  useEffect(() => {
    window.utag?.view({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.pageView],
    });
  }, []);

  return (
    <Slider
      onClickOutside={ () => setShow(false) }
      slot={
        <div className='advance-info v-container'>
          <SpaceMan />
          <h3 className='heading-content'><p>Three steps to enjoy</p><p>Voucher Advance</p></h3>
          <ol className='list-content'>
            <li>Enjoy your voucher now, and pay back later! Zero fees!</li>
            <li>You can pay your voucher advance early to access more.</li>
            <li>Spend the voucher online or in-store.</li>
          </ol>
          <button
            className='main-button'
            onClick={ () => setShow(false) }
          >
            Got it!
          </button>
        </div>
      }
    />
  );
}

AdvanceInfo.propTypes = { setShow: PropTypes.func };
