import React from 'react';
import AddToCartButtons from '../AddToCart/AddToCartButtons.jsx';
import { Voucher } from '../../services/Constants/ObjectDefinitions.js';
import PropTypeShapes from '../../services/Constants/PropTypeShapes.jsx';
import StockStatus from '../../services/Constants/StockStatus.js';

/**
 * @param {object} props - the props of the component
 * @param {Voucher} props.voucher - the voucher to check
 * @returns {JSX.Element} StockChecker component
 */
export default function StockChecker({ voucher }) {
  return voucher.stock.status === StockStatus.inStock ? <AddToCartButtons voucher={ voucher } /> : <>Out of stock</>;
}

StockChecker.propTypes = { voucher: PropTypeShapes.voucher };
