import HttpCodes from './HttpCodes.js';

export class NetworkError extends Error {
  /**
   * @param {Response} response - Network Response
   */
  constructor(response) {
    super();
    this.name = 'NetworkError';
    this.response = response;
  }
}

export class VoucherAddError extends Error {
  /**
   * @param {NetworkError} networkError - Add Network error
   */
  constructor(networkError) {
    super();
    this.name = 'VoucherAddError';
    this.addError = networkError;
  }
}

export class VoucherReservationError extends Error {
  /* eslint-disable jsdoc/no-undefined-types */
  /**
   * @param {Array<PromiseSettledResult<Awaited<Response>>>} reservationResponses - array of settled reservation promises
   */
  constructor(reservationResponses) {
    super();
    this.name = 'VoucherReservationError';
    this.responses = reservationResponses;
  }
}

/**
 * @param {NetworkError} networkError - reservation error response
 * @returns {Promise<string>} - reservation failure reason
 */
export const getReservationFailureReason = async networkError => {
  if (networkError.response.status === HttpCodes.INTERNAL_SERVER_ERROR) return null;
  if (networkError.response.status === HttpCodes.CONFLICT) {
    const response = await networkError.response.json();
    return response.data.reservationFailureReason;
  }
};
