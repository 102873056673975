import { createContext, Context, Dispatch, SetStateAction } from 'react';

export const PaymentTouchedContext = createContext(false);

/**
 * @type {Context<Dispatch<SetStateAction<boolean>>>}
 */
export const UpdatePaymentTouchedContext = createContext(def => def);

export const PaymentAmountContext = createContext('');

/**
 * @type {Context<Dispatch<SetStateAction<string>>>}
 */
export const UpdatePaymentAmountContext = createContext(def => def);
