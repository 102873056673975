import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AnalyticsPopupContext, PopupContext, UpdatePopupContext, UpdateAnalyticsPopupContext } from '../services/context/PopupContext.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function PopupController({ slot }) {
  const [popup, setPopup] = useState(null);
  const [popupAnalytic, setPopupAnalytic] = useState(null);

  return (
    <PopupContext.Provider value={ popup }>
      <UpdatePopupContext.Provider value={ setPopup }>
        <UpdateAnalyticsPopupContext.Provider value={ setPopupAnalytic }>
          <AnalyticsPopupContext.Provider value={ popupAnalytic }>
            {slot}
          </AnalyticsPopupContext.Provider>
        </UpdateAnalyticsPopupContext.Provider>
      </UpdatePopupContext.Provider>
    </PopupContext.Provider>
  );
}

PopupController.propTypes = { slot: PropTypes.element };
