import { MutableRefObject, useRef } from 'react';

/**
 * @param {MutableRefObject<HTMLElement>} [back] - a reference to the backside element of the tile
 * @returns {{
 * onTouchStart: function(TouchEvent),
 * onTouchMove: function(TouchEvent),
 * onTouchEnd: function(TouchEvent)
 * }} - element onTouch event handlers
 */
export default function useTile3D(back) {
  const touchElementRef = useRef(/** @type {HTMLElement} */null);
  const rectRef = useRef(/** @type {DOMRect} */null);
  const xRotationCss = '--x-rotation';
  const yRotationCss = '--y-rotation';
  const rotationDegrees = 20;
  const rotationBounds = 0;

  /**
   * @param {TouchEvent} event - touch start event
   */
  const onTouchStart = event => {
    touchElementRef.current = event.currentTarget;
    rectRef.current = touchElementRef.current.getBoundingClientRect();
  };

  /**
   * @param {TouchEvent} event - touch move event
   */
  const onTouchMove = event => {
    if (!touchElementRef.current) return;
    const [touch] = event.touches;
    const x = Math.min(Math.max(touch.clientX - rectRef.current.left, rotationBounds), rectRef.current.width - rotationBounds);
    const y = Math.min(Math.max(touch.clientY - rectRef.current.top, rotationBounds), rectRef.current.height - rotationBounds);
    const xPercentage = x / rectRef.current.width;
    const yPercentage = y / rectRef.current.height;

    const xRotation = (xPercentage - 0.5) * rotationDegrees;
    const yRotation = (0.5 - yPercentage) * rotationDegrees;

    back?.current.style.setProperty(xRotationCss, `${yRotation}deg`);
    back?.current.style.setProperty(yRotationCss, `${-xRotation}deg`);

    touchElementRef.current.style.setProperty(xRotationCss, `${yRotation}deg`);
    touchElementRef.current.style.setProperty(yRotationCss, `${xRotation}deg`);
  };

  const onTouchEnd = () => {
    back?.current.style.removeProperty(xRotationCss);
    back?.current.style.removeProperty(yRotationCss);
    touchElementRef.current.style.removeProperty(xRotationCss);
    touchElementRef.current.style.removeProperty(yRotationCss);
    touchElementRef.current = null;
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
}
