import React from 'react';
import './BottomNav.css';
import NavHome from '../NavItem/Home/NavHome.jsx';
import NavMyVouchers from '../NavItem/My Vouchers/NavMyVouchers.jsx';
import NavCart from '../NavItem/Cart/NavCart.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';

/**
 * @returns {JSX.Element} BottomNav Component
 */
export default function BottomNav() {
  const navigateTo = useNavigate();
  const location = useLocation();

  return (
    <nav className='bottom-nav'>
      <div className='v-container'>
        <button
          className='container-h'
          onClick={ () => navigateTo(ROUTES.HOME) }
        >
          <NavHome />
        </button>
      </div>
      <div className='v-container'>
        <button
          className='container-h'
          onClick={ () => navigateTo(ROUTES.MY_VOUCHERS) }
        >
          <NavMyVouchers />
        </button>
      </div>
      <div className='v-container'>
        <button
          className='container-h'
          onClick={ () => (location.pathname === ROUTES.CART ? navigateTo(ROUTES.CART) : navigateTo(ROUTES.CROSS_SELL)) }
        >
          <NavCart />
        </button>
      </div>
    </nav>
  );
}
