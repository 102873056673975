/**
 * @param {Array} arr - array to get entries from
 * @param {number} numEntries - number of entries to get from the array
 * @returns {Array} - random subset of arr
 */
export const getRandomEntries = (arr, numEntries) => {
  if (arr.length <= numEntries) {
    return arr;
  }

  const randomEntries = [];
  while (randomEntries.length < numEntries) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    randomEntries.push(arr[randomIndex]);
    arr.splice(randomIndex, 1);
  }

  return randomEntries;
};
