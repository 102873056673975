import React, { useState } from 'react';
import './RocketAnimation.css';
import Rocket from '../../assets/Rocket.jsx';
import PropTypes from 'prop-types';

/**
 * @param {object} props - the props of the component
 * @param {boolean} props.isFailure - whether success or failure
 * @returns {JSX.Element} SuccessRocket Component
 */
export default function RocketAnimation({ isFailure }) {
  const [animating, setAnimating] = useState(true);
  setTimeout(() => setAnimating(false), 50);
  return (
    <div className='success-rocket v-container-vh'>
      <div className={ `wrapper${animating ? ' animating' : ''}${isFailure ? ' bad' : ''}` }>
        <Rocket />
      </div>
      <h3>{isFailure ? 'Something went wrong...' : 'Payment successful!'}</h3>
    </div>
  );
}

RocketAnimation.propTypes = { isFailure: PropTypes.bool };
