import React from 'react';

/* eslint-disable node/file-extension-in-import */
import ReactDOM from 'react-dom/client'; // for some reason adding .js here breaks the app
import 'core-js/actual';
/* eslint-enable node/file-extension-in-import */
import App from './App.jsx';
import './index.css';

window.utag ??= undefined;

ReactDOM.createRoot(document.querySelector('#root')).render(<React.StrictMode>
  <App />
</React.StrictMode>);
