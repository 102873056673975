import React, { useContext } from 'react';
import './ExpandedCouponBottom.css';
import CouponCode from '../../CouponCode/CouponCode.jsx';
import { MoneyFormatterContext } from '../../../services/context/MoneyFormatterContext.js';
import PropTypeShapes from '../../../services/Constants/PropTypeShapes.jsx';
import { Gift } from '../../../services/Constants/ObjectDefinitions.js';

/**
 *
 * @param {object} props - the props of the component
 * @param {Gift} props.gift - the gift associated with the coupon
 * @returns {JSX.Element} ExpandedCouponBottom component
 */
export default function ExpandedCouponBottom({ gift }) {
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const date = new Date(gift.expiryDate);
  const dateFormatter = new Intl.DateTimeFormat('en-ZA', {
    dateStyle: 'long',
    timeStyle: 'short',
  });
  const redemptionText = gift?.voucher?.redemption.type;

  return (
    <div className='expanded-bottom v-container'>
      <header className='v-container-h'>
        <h3>
          {MoneyFormatter.format(gift.balance)}
        </h3>

        <p>
          {redemptionText}
        </p>
      </header>

      <CouponCode
        gift={ gift }
        noCopy
      />

      <footer className='extra-info v-container-h'>
        <p>
          Valid until
          {' '}
          {dateFormatter.format(date).replace('at', '|')}
        </p>
      </footer>
    </div>
  );
}

ExpandedCouponBottom.propTypes = { gift: PropTypeShapes.gift };
