import React, { useContext } from 'react';
import './AddToCart.css';
import CartIcon from '../../assets/CartIcon.jsx';
import BinIcon from '../../assets/BinIcon.jsx';
import PlusIcon from '../../assets/PlusIcon.jsx';
import { getLastIndexById } from '../../services/Find.js';
import PropTypeShapes from '../../services/Constants/PropTypeShapes.jsx';
import { CartContext, UpdateCartContext } from '../../services/context/CartContext.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { getVendorCategory, getVoucherVendor } from '../../services/ReverseHierarchySearch.js';
import { CategoriesContext } from '../../services/context/CategoriesContext.js';
import { Voucher } from '../../services/Constants/ObjectDefinitions.js';
import MinusIcon from '../../assets/MinusIcon.jsx';
import { UpdateSnackBarContext } from '../../services/context/SnackBarContext.js';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import FlagIcon from '../../assets/FlagIcon.jsx';
import { cartMax } from '../../services/Constants/config.js';

/**
 *
 * @param {object} props - the props of the component
 * @param {Voucher} props.voucher - the voucher associated with the component
 * @returns {JSX.Element} AddToCartButtons Component
 */
export default function AddToCartButtons({ voucher }) {
  const cart = useContext(CartContext);
  const setCart = useContext(UpdateCartContext);
  const analyticsPage = useContext(AnalyticsPageContext);
  const categories = useContext(CategoriesContext);
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const updateSnackBar = useContext(UpdateSnackBarContext);

  const cartAnalyticsData = {
    ...defaultAnalyticsVariables,
    page_name: analyticsPage,
    product_category: getVendorCategory(categories, getVoucherVendor(categories, voucher))?.name,
    product_name: voucher.name,
    product_price: MoneyFormatter.getValue(voucher.cost).amount,
    product_id: voucher.id,
    product_quantity: 1,
  };
  const decrease = () => {
    window.utag?.link({
      ...cartAnalyticsData,
      event_name: [
        events.interaction,
        events.removeFromCart,
      ],
      link_id: `${pagePrefix}: cart remove`,
    });

    const lastIndex = getLastIndexById(cart, voucher);

    setCart([
      ...cart.slice(0, lastIndex),
      ...cart.slice(lastIndex + 1),
    ]);
  };

  const increase = () => {
    if (cart.length >= cartMax) {
      updateSnackBar({
        icon: <FlagIcon />,
        body: 'Cart limit reached',
      });
      return;
    }

    window.utag?.link({
      ...cartAnalyticsData,
      event_name: [
        events.interaction,
        events.addToCart,
      ],
      link_id: `${pagePrefix}: cart add`,
    });

    setCart([
      ...cart,
      voucher,
    ]);
  };

  const num = cart.filter(item => item.id === voucher.id).length;

  return (
    <div className='add-to-cart container-vh'>
      {
        num === 0
        && <button
          onClick={ increase }
          className='icon'
        >
          <CartIcon />
        </button>

      }
      {
        num > 0
        && <>
          <button
            onClick={ decrease }
            className='icon'
          >
            {num > 1 ? <MinusIcon /> : <BinIcon />}
          </button>
          <p className='amount'>{num}</p>
          <button
            onClick={ increase }
            className='icon'
          >
            <PlusIcon />
          </button>

        </>
      }
    </div>
  );
}

AddToCartButtons.propTypes = { voucher: PropTypeShapes.voucher };
