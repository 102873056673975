import React, { useContext, useEffect, useState } from 'react';
import useApis from '../services/hooks/useApis.js';
import { BearerTokenContext } from '../services/context/BearerTokenContext.js';
import { EligibilityContext, UpdateEligibilityContext } from '../services/context/EligibilityContext.js';
import PropTypes from 'prop-types';
import { ConfigContext } from '../services/context/ConfigContext.js';
import { UserDetailsContext } from '../services/context/UserDetailsContext.js';
import { Eligibility } from '../services/Constants/ObjectDefinitions.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function EligibilityController({ slot }) {
  const [eligibility, setEligibility] = useState(/** @type {Eligibility} */null);
  const bearerToken = useContext(BearerTokenContext);
  const { vsp } = useContext(UserDetailsContext);
  const { getAdvanceEligibility } = useApis();
  const config = useContext(ConfigContext);

  useEffect(() => {
    if (bearerToken && config && !eligibility) {
      if (vsp) {
        getAdvanceEligibility(bearerToken)
          .then(setEligibility);
      } else {
        setEligibility({
          eligibility: 'NE_NVC',
          eligibilityRequestId: null,
          value: null,
        });
      }
    }
  }, [
    bearerToken,
    config,
    eligibility,
  ]);

  return (
    <EligibilityContext.Provider value={ eligibility }>
      <UpdateEligibilityContext.Provider value={ setEligibility }>
        {slot}
      </UpdateEligibilityContext.Provider>
    </EligibilityContext.Provider>
  );
}

EligibilityController.propTypes = { slot: PropTypes.element };
