import React from 'react';
import './ToggleButton.css';
import PropTypes from 'prop-types';

/**
 * @param {object} props - the props of the component
 * @param {boolean} props.isChecked - whether the checkbox is checked
 * @param {Function} props.onChange - function to handle onChange event
 * @returns {JSX.Element} ToggleButton Component
 */
export default function ToggleButton({ isChecked, onChange }) {
  return (
    <label className='toggle-button'>
      <input
        type='checkbox'
        checked={ isChecked }
        onChange={ onChange }
      />
      <div className='round-slider' />
    </label>
  );
}

ToggleButton.propTypes = {
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};
