import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Component that navigates to the specified route on render
 *
 * @param {*} props - the props of the component
 * @param {string} props.to - the route to redirect to;
 * @returns {JSX.Element} - empty component
 */
export default function Redirect({ to }) {
  const navigate = useNavigate();

  useEffect(() => navigate(to, { replace: true }));
  return null;
}

Redirect.propTypes = { to: PropTypes.string };
