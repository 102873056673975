import { useContext } from 'react';
import { MoneyFormatterContext } from '../context/MoneyFormatterContext.js';
import { Voucher } from '../Constants/ObjectDefinitions.js';

export const useCostSort = () => {
  const MoneyFormatter = useContext(MoneyFormatterContext);
  /**
   * @param {Voucher} a - first Voucher
   * @param {Voucher} b - second Voucher
   * @param {boolean} [greaterFirst] - flips the return value if true
   * @returns {number} - numeric difference in value between the vouchers
   */
  const costSort = (a, b, greaterFirst = false) => {
    const { amount } = MoneyFormatter.subtract(MoneyFormatter.getValue(b.cost), MoneyFormatter.getValue(a.cost));

    if (greaterFirst) {
      return -amount;
    }

    return amount;
  };

  return { costSort };
};
