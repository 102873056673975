import { useContext } from 'react';
import { MoneyFormatterContext } from '../context/MoneyFormatterContext.js';
import { Voucher } from '../Constants/ObjectDefinitions.js';

export default () => {
  const MoneyFormatter = useContext(MoneyFormatterContext);

  /**
   * @param {Voucher} voucher - voucher to get percent added value for
   * @returns {number} - percentage value as a percentage
   */
  const getBuyNowPercentValue = voucher => (MoneyFormatter.subtract(
    MoneyFormatter.getValue(voucher.cost),
    MoneyFormatter.getValue(voucher.options.buyNow.value),
  ).amount / MoneyFormatter.getValue(voucher.cost).amount * 100);

  /**
   * @param {Voucher} voucherA - first voucher
   * @param {Voucher} voucherB - second voucher
   * @returns {Voucher} - the voucher with the higher percentage return
   */
  const getBestValueVoucher = (voucherA, voucherB) => (
    (getBuyNowPercentValue(voucherA))
    >= (getBuyNowPercentValue(voucherB))
      ? voucherA
      : voucherB);

  return {
    getBuyNowPercentValue,
    getBestValueVoucher,
  };
};
