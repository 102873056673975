import React from 'react';
import './CarouselItem.css';
import PropTypes from 'prop-types';

/**
 * @param {object} props - the props of the component
 * @param {string} [props.headerText] - the main text of the carousel item
 * @param {string} [props.subText] - the sub text of the carousel item
 * @param {Element} [props.actionItem] - the action item for the carousel item
 * @returns {Element} CarouselItem component
 */
export default function CarouselItem({ headerText, subText, actionItem }) {
  return (
    <div className='carousel-item container-v'>
      <header className='v-container'>
        <div>
          {headerText ? <h3>{headerText}</h3> : null }
          {subText ? <p>{subText}</p> : null }
        </div>
        {actionItem}
      </header>
    </div>
  );
}

CarouselItem.propTypes = {
  headerText: PropTypes.string,
  subText: PropTypes.string,
  actionItem: PropTypes.element,
};
