import { createContext, Context, Dispatch, SetStateAction } from 'react';
import { Eligibility } from '../Constants/ObjectDefinitions.js';

/**
 * @type {Context<Eligibility>}
 */
export const EligibilityContext = createContext(undefined);

/**
 * @type {Context<Dispatch<SetStateAction<Eligibility>>>}
 */
export const UpdateEligibilityContext = createContext(def => def);
