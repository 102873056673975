import React from 'react';
import './VouchersDisplay.css';
import PropTypes from 'prop-types';

/**
 * @param {object} props - The props for the component
 * @param {JSX.Element} props.title - title element
 * @param {boolean} props.isLoading - Whether to show onLoading element or not
 * @param {JSX.Element | JSX.Element[]} props.onLoading - element to show when loading
 * @param {JSX.Element} props.onEmpty - element to show when vouchers is empty
 * @param {JSX.Element[]}props.vouchers - elements to show
 * @returns {JSX.Element} VouchersDisplay Component
 */
export default function VouchersDisplay({ title, isLoading, onLoading, onEmpty, vouchers }) {
  return (
    <section className='vouchers-display v-container'>
      {title}
      <ul className='v-container'>
        {vouchers}
        {
          isLoading
          && onLoading
        }
        {
          (!isLoading && (!vouchers || vouchers.length === 0))
          && onEmpty
        }
      </ul>
    </section>
  );
}

VouchersDisplay.propTypes = {
  title: PropTypes.element,
  onLoading: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  isLoading: PropTypes.bool,
  vouchers: PropTypes.arrayOf(PropTypes.element),
  onEmpty: PropTypes.element,
};
