import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AdvanceContext, UpdateAdvanceContext } from '../services/context/AdvanceContext.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function AdvanceController({ slot }) {
  const [advance, setAdvance] = useState();
  return (
    <AdvanceContext.Provider value={ advance }>
      <UpdateAdvanceContext.Provider value={ setAdvance }>
        {slot}
      </UpdateAdvanceContext.Provider>
    </AdvanceContext.Provider>
  );
}

AdvanceController.propTypes = { slot: PropTypes.element };
