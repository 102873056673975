import React, { useContext, useState } from 'react';
import './VoucherInfo.css';
import {
  CurrentCouponContext,
  UpdateCurrentCouponLoanDetailsContext,
} from '../../services/context/CurrentCouponContext.js';
import { CategoriesContext } from '../../services/context/CategoriesContext.js';
import { useNavigate } from 'react-router-dom';
import KeyValueList from '../../components/KeyValueList/KeyValueList.jsx';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import CopyIcon from '../../assets/CopyIcon.jsx';
import { ACTIVE_STATUSES } from '../../services/ActiveStatuses.js';
import TopBack from '../../components/TopBack/TopBack.jsx';
import { getVoucherVendor } from '../../services/ReverseHierarchySearch.js';
import { iOs } from '../../services/iOs.js';
import DualButtons from '../../components/DualButtons/DualButtons.jsx';
import { EligibilityContext } from '../../services/context/EligibilityContext.js';
import Redirect from '../../components/Redirect/Redirect.jsx';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { UpdateSnackBarContext } from '../../services/context/SnackBarContext.js';
import { UpdatePopupContext } from '../../services/context/PopupContext.js';
import InvoiceEmailCapture from '../../components/EmailCapture/InvoiceEmailCapture.jsx';
import useApis from '../../services/hooks/useApis.js';
import { BearerTokenContext } from '../../services/context/BearerTokenContext.js';
import SuccessTickIcon from '../../assets/SuccessTickIcon.jsx';
import FailIcon from '../../assets/FailIcon.jsx';
import { captureException } from '@sentry/react';
import { NetworkError } from '../../services/Constants/Errors.js';
import Loading from '../../components/Loading/Loading.jsx';

/**
 * @returns {JSX.Element} VoucherInfo component
 */
export default function VoucherInfo() {
  const gift = useContext(CurrentCouponContext);
  const eligibility = useContext(EligibilityContext);
  const categories = useContext(CategoriesContext);
  const setSnackBar = useContext(UpdateSnackBarContext);
  const setPopup = useContext(UpdatePopupContext);
  const setLoanDetails = useContext(UpdateCurrentCouponLoanDetailsContext);
  const bearerToken = useContext(BearerTokenContext);
  const { resendInvoice, getLoanDetails } = useApis();
  const [loading, setLoading] = useState(/** @type {string} */null);
  const navigateTo = useNavigate();
  const moneyFormatter = useContext(MoneyFormatterContext);
  const analyticsName = `${pagePrefix}: voucher info`;
  const pageLinkAnalytics = {
    ...defaultAnalyticsVariables,
    page_name: analyticsName,
    event_name: [events.interaction],
  };
  const dateFormatter = new Intl.DateTimeFormat('en-ZA', {
    dateStyle: 'long',
    timeStyle: 'short',
  });

  if (!gift) return <Redirect to={ ROUTES.MY_VOUCHERS } />;

  const onShare = () => {
    window.utag?.link({
      ...pageLinkAnalytics,
      link_id: `${pagePrefix}: share voucher`,
    });
    navigateTo(ROUTES.SHARE);
  };

  const onManagePayments = () => {
    setLoading('Getting Voucher details...');
    getLoanDetails(bearerToken, gift.reservationId)
      .then(data => data.balance ?? {})
      .then(balance => {
        setLoanDetails(balance);
        window.utag?.link({
          ...pageLinkAnalytics,
          link_id: `${pagePrefix}: manage payments`,
        });
        navigateTo(ROUTES.MANAGE_ADVANCE);
      })
      .finally(() => setLoading(null));
  };

  const onBuyAgain = () => {
    const query = gift.isAdvance && eligibility.tcl
      ? { advance: gift.voucher.id }
      : { voucher: gift.voucher.id };
    navigateTo(`${ROUTES.VENDOR.get(getVoucherVendor(categories, gift.voucher).id)}?${new URLSearchParams(query)}`);
  };

  /**
   * @returns {JSX.Element} Nav Section component
   */
  function NavSection() {
    const share = 'Share voucher';
    const manage = 'Manage voucher';
    const buy = 'Buy again';
    if (!iOs()) {
      if (gift.advanceHistory) {
        return (
          <DualButtons
            primaryText={ share }
            primaryOnClick={ onShare }
            secondaryText={ manage }
            secondaryOnClick={ onManagePayments }
          />
        );
      }

      if (gift.voucher) {
        return (
          <DualButtons
            secondaryText={ share }
            secondaryOnClick={ onShare }
            primaryText={ buy }
            primaryOnClick={ onBuyAgain }
          />
        );
      }

      return (
        <button
          className='main-button'
          onClick={ onShare }
        >
          {share}
        </button>
      );
    }

    return gift.advanceHistory
      ? (
        <button
          className='main-button'
          onClick={ onManagePayments }
        >
          {manage}
        </button>)
      : (
        <button
          className='main-button'
          onClick={ onBuyAgain }
        >
          {buy}
        </button>);
  }

  return (
    <AnalyticsPageContext.Provider value={ analyticsName }>

      <article className='voucher-info v-container'>
        <TopBack />
        <h1>{getVoucherVendor(categories, gift.voucher)?.name ?? gift.name}</h1>
        <main>
          <KeyValueList object={
            {
              'Voucher amount': moneyFormatter.format(gift.balance),
              'Voucher code': <>{gift.redemptionCode}
                <button onClick={
                  () => {
                    navigator.clipboard.writeText(gift.redemptionCode)
                      .then(() => setSnackBar({
                        icon: <CopyIcon />,
                        body: 'Voucher code copied',
                      }))
                      .catch(() => setSnackBar({
                        icon: <CopyIcon />,
                        body: 'Failed to copy',
                      }));
                  }
                }
                ><CopyIcon /></button>
              </>,
              ...(gift.redemptionPin ? {
                'Voucher PIN': <>{gift.redemptionPin}
                  <button onClick={
                    () => {
                      navigator.clipboard.writeText(gift.redemptionPin)
                        .then(() => setSnackBar({
                          icon: <CopyIcon />,
                          body: 'Voucher PIN copied',
                        }))
                        .catch(() => setSnackBar({
                          icon: <CopyIcon />,
                          body: 'Failed to copy',
                        }));
                    }
                  }
                  ><CopyIcon /></button>
                </>,
              } : {}),
            }
          }
          />
          <KeyValueList object={
            {
              'Voucher status': ACTIVE_STATUSES.includes(gift.status) ? 'Active' : gift.status,
              'Purchase date': dateFormatter.format(new Date(gift.purchaseDate)).replace('at', '|'),
              'Valid until': dateFormatter.format(new Date(gift.expiryDate)).replace('at', '|'),
              ...(!gift.isVirtual
                ? {
                  'Tax invoice': <button onClick={
                    () => setPopup(<InvoiceEmailCapture onCapture={
                      async userDetails => {
                        setLoading('Hang tight, we’re sending your tax invoice');
                        resendInvoice(bearerToken, userDetails, gift.orderId)
                          .then(() => setSnackBar({
                            icon: <SuccessTickIcon />,
                            body: 'Tax invoice sent successfully.',
                          }))
                          .catch(error => {
                            if (error instanceof NetworkError) captureException(new Error(`Failed to request invoice: ${error.response.status}`));
                            else captureException(error);
                            setSnackBar({
                              icon: <FailIcon />,
                              body: 'Tax invoice not sent. Please try again later.',
                            });
                          })
                          .finally(() => {
                            setLoading(null);
                          });
                      }
                    }
                    />)
                  }
                  >Request</button>,
                }
                : {}),
            }
          }
          />
        </main>
        <NavSection />
      </article>
      { loading && <Loading headerText={ loading } /> }
    </AnalyticsPageContext.Provider>
  );
}
