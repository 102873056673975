import { createContext, Context } from 'react';
import { Category } from '../Constants/ObjectDefinitions.js';

/**
 * @type {Context<Category[]>}
 */
export const CategoriesContext = createContext(undefined);

/**
 * @type {Context<boolean>}
 */
export const CategoriesCompleteContext = createContext(undefined);
