import React, { useContext, useState } from 'react';
import './RocketHeader.css';
import SuccessTickIcon from '../../assets/SuccessTickIcon.jsx';
import { RocketContext } from '../../services/context/RocketContext.js';
import FailIcon from '../../assets/FailIcon.jsx';
import PropTypes from 'prop-types';

/**
 @param {object} props - the props of the component
 @param {boolean} props.isFailure - whether success or failure
 * @returns {JSX.Element} SuccessHeader Component
 */
export default function RocketHeader({ isFailure }) {
  const [animating, setAnimating] = useState(true);
  const { header } = useContext(RocketContext);
  setTimeout(() => {
    setAnimating(false);
  }, 50);
  return (
    <header className={ `success-header v-container-h${animating ? ' animating' : ''}` }>
      {
        isFailure
          ? <FailIcon />
          : <SuccessTickIcon />
      }
      {header}
    </header>
  );
}

RocketHeader.propTypes = { isFailure: PropTypes.bool };
