import { createContext, Context, Dispatch, SetStateAction } from 'react';
import { Voucher } from '../Constants/ObjectDefinitions.js';

/**
 * @type {Context<Array<Voucher>>}
 */
export const CartContext = createContext(undefined);

/**
 * @type {Context<Dispatch<SetStateAction<Array<Voucher>>>>}
 */
export const UpdateCartContext = createContext(def => def);
