import React, { useContext, useEffect, useState } from 'react';
import ManagePaymentDetails from '../../components/ManagePaymentDetails/ManagePaymentDetails.jsx';
import DualButtons from '../../components/DualButtons/DualButtons.jsx';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { defaultAnalyticsVariables, events, pagePrefix, traceId } from '../../services/Constants/Analytics.js';
import { UpdateMiniAppChangeCardContext } from '../../services/context/MiniAppContext.js';
import Loading from '../../components/Loading/Loading.jsx';
import MiniAppMessageTypes from '../../services/Constants/MiniAppMessageTypes.js';
import useApis from '../../services/hooks/useApis.js';
import { BearerTokenContext, UpdateBearerTokenContext } from '../../services/context/BearerTokenContext.js';
import useAuthService from '../../services/hooks/useAuthService.js';
import { CurrentCouponContext, CurrentCouponLoanDetailsContext } from '../../services/context/CurrentCouponContext.js';
import { UpdateSnackBarContext } from '../../services/context/SnackBarContext.js';
import BasketIcon from '../../assets/BasketIcon.jsx';
import VoucherIcon from '../../assets/VoucherIcon.jsx';
import { captureException, captureMessage, startTransaction, withProfiler } from '@sentry/react';
import HttpCodes from '../../services/Constants/HttpCodes.js';
import { withSentrySpan } from '../../services/Sentry.js';
import { SentryConfig } from '../../services/Constants/Sentry.js';
import { NetworkError } from '../../services/Constants/Errors.js';

/**
 * @returns {JSX.Element} ManagePaymentPage Component
 */
function ManagePaymentPage() {
  const bearerTokenContext = useContext(BearerTokenContext);
  const { changeCardCall, getBearerToken } = useApis();
  const { doAuth } = useAuthService();
  const { advanceHistory: { advanceDetail: { orderId, loanMaturity }, purchaseDate } } = useContext(CurrentCouponContext);
  const advanceHistory = useContext(CurrentCouponLoanDetailsContext);
  const setMiniAppChangeCardHandler = useContext(UpdateMiniAppChangeCardContext);
  const setSnackBar = useContext(UpdateSnackBarContext);
  const updateBearerToken = useContext(UpdateBearerTokenContext);
  const [changeResponse, setChangeResponse] = useState(null);
  const [loading, setLoading] = useState/** @type {string} */(null);
  const analyticsName = `${pagePrefix}: manage payments`;
  useEffect(() => {
    window.utag?.view({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.pageView],
    });
  }, []);

  const navigateTo = useNavigate();
  const continueText = <>Pay back advance</>;
  const changeCardText = <>Change card</>;
  const continueOnClick = () => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [
        events.interaction,
        events.journeyStart,
      ],
      link_id: `${pagePrefix}: my vouchers: pay back advance`,
      journey_name: 'pay advance',
    });
    navigateTo(ROUTES.PAYMENT_SELECT);
  };

  const changeCard = async (bearerToken, transaction = startTransaction(SentryConfig.admin.changeCard.transaction)) => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.interaction],
      link_id: `${pagePrefix}: my vouchers: change card`,
    });
    try {
      setLoading('Getting ready to change card...');
      const authCode = await doAuth();
      const firstRepaymentDate = new Date(purchaseDate);
      firstRepaymentDate.setDate(firstRepaymentDate.getDate() + loanMaturity);
      const response = await withSentrySpan(
        transaction,
        SentryConfig.admin.changeCard.spans.changeCardCall,
        async () => await changeCardCall(bearerToken, authCode, {
          orderId,
          firstRepaymentDate,
        }),
      );
      const paymentUrl = response.result.initiationUrl;
      if (!window.my) {
        // eslint-disable-next-line no-alert
        const mocked = confirm('mock success?');
        setChangeResponse({ ok: mocked });
      } else {
        setMiniAppChangeCardHandler(() => message => setChangeResponse(message.data));
        window.my.postMessage({
          messageType: MiniAppMessageTypes.changeCard,
          data: { paymentUrl },
        }, '*');
      }
    } catch (error) {
      setChangeResponse({
        ok: false,
        error,
      });
    }
  };

  useEffect(() => {
    if (changeResponse) {
      setLoading(null);
      if (changeResponse.ok) {
        setSnackBar({
          icon: <VoucherIcon />,
          body: 'Card Changed Successfully',
        });
      } else {
        const { error } = changeResponse;
        if (error instanceof NetworkError) {
          const { response } = error;
          switch (response.status) {
            case HttpCodes.UNAUTHORIZED:
              // falls through

            case HttpCodes.FORBIDDEN: {
              setLoading('Retrying');
              doAuth()
                .then(getBearerToken)
                .then(bearerResponse => {
                  updateBearerToken(bearerResponse.data.tokens.accessToken);
                  return changeCard(bearerResponse.data.tokens.accessToken);
                });
              break;
            }

            default: {
              window.utag?.link({
                ...defaultAnalyticsVariables,
                page_name: analyticsName,
                event_name: [events.error],
                link_id: `${pagePrefix}: error`,
                event_error_name: 'change card network error',
                event_error_code: response.headers?.get(traceId),
                event_error_type: 'system error',
              });
              captureMessage(`Unexpected network response in change card: ${response.status}`, 'warning');
              setSnackBar({
                icon: <BasketIcon />,
                body: 'Whoops, Something went wrong',
              });
            }
          }
        } else {
          window.utag?.link({
            ...defaultAnalyticsVariables,
            page_name: analyticsName,
            event_name: [events.error],
            link_id: `${pagePrefix}: error`,
            event_error_name: 'change card miniapp error',
            event_error_type: 'system error',
          });
          captureException(new Error('MiniApp change card error'));
          setSnackBar({
            icon: <BasketIcon />,
            body: 'Whoops, Something went wrong',
          });
        }
      }
    }
  }, [changeResponse]);

  return (
    <AnalyticsPageContext.Provider value={ analyticsName }>
      <ManagePaymentDetails />
      {
        advanceHistory.outstanding
          ? (
            <DualButtons
              secondaryOnClick={ () => changeCard(bearerTokenContext) }
              secondaryText={ changeCardText }
              primaryOnClick={ continueOnClick }
              primaryText={ continueText }
            />)
          : null
      }
      {loading ? <Loading headerText={ loading } /> : null}
    </AnalyticsPageContext.Provider>
  );
}

export default withProfiler(ManagePaymentPage);
