import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MaintenanceMessageContext, UpdateMaintenanceMessageContext } from '../services/context/MaintenanceContext.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The SuccessController Component
 */
export default function MaintenanceController({ slot }) {
  const [maintenanceMessage, setMaintenanceMessage] = useState(null);

  return (
    <MaintenanceMessageContext.Provider value={ maintenanceMessage }>
      <UpdateMaintenanceMessageContext.Provider value={ setMaintenanceMessage }>
        { slot }
      </UpdateMaintenanceMessageContext.Provider>
    </MaintenanceMessageContext.Provider>
  );
}

MaintenanceController.propTypes = { slot: PropTypes.element };
