import React, { useContext } from 'react';
import './Popup.css';
import CloseIcon from '../../assets/CloseIcon.jsx';
import { PopupContext, UpdatePopupContext, AnalyticsPopupContext } from '../../services/context/PopupContext.js';

/**
 * @returns {JSX.Element} Popup Component
 */
export default function Popup() {
  const body = useContext(PopupContext);
  const setPopup = useContext(UpdatePopupContext);
  const analyticsPopup = useContext(AnalyticsPopupContext);

  const popupCrossAnalytics = () => {
    if (analyticsPopup) {
      window.utag?.link({ ...analyticsPopup });
    }
  };

  if (!body) return null;
  return (
    <div
      className='popup v-container'
      onClick={
        () => {
          popupCrossAnalytics();
          setPopup(null);
        }
      }
    >
      <article
        className='v-container'
        onClick={ event => event.stopPropagation() }
      >
        <button onClick={
          () => {
            popupCrossAnalytics();
            setPopup(null);
          }
        }
        ><CloseIcon /></button>
        {body}
      </article>
    </div>
  );
}
