import React, { useContext, useEffect } from 'react';
import './AdvanceDateDetails.css';
import DatePicker from '../DatePicker/DatePicker.jsx';
import { getAdvanceDetails } from '../../services/StringProcessor.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { AdvanceContext, UpdateAdvanceContext } from '../../services/context/AdvanceContext.js';
import { stringToDate } from '../../services/Dates.js';
import { EligibilityContext } from '../../services/context/EligibilityContext.js';
import { createIsAdvanceOptionFilter } from '../../services/EligibilityOffers.js';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';

/**
 * @returns {JSX.Element} - AdvanceDateDetails component
 */
export default function AdvanceDateDetails() {
  const analyticsName = `${pagePrefix}: advance checkout`;
  const navigateTo = useNavigate();
  const eligibility = useContext(EligibilityContext);
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const [advancing, setAdvancing] = [
    useContext(AdvanceContext),
    useContext(UpdateAdvanceContext),
  ];
  const { voucher, date } = advancing;

  useEffect(() => {
    window.utag?.view({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.pageView],
    });
  }, []);

  const handleContinue = () => {
    const today = new Date(Date.now());
    const diff = date.getTime() - today.getTime();
    const days = Math.ceil(diff / (1000 * 3600 * 24));
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.interaction],
      link_id: `${pagePrefix}: accept & continue`,
      smme_bca_payment_period: `${days} days`,
    });

    navigateTo(ROUTES.ADVANCE_SUMMARY);
  };

  useEffect(() => {
    if (date) {
      window.utag?.link({
        ...defaultAnalyticsVariables,
        page_name: analyticsName,
        event_name: [events.interaction],
        link_id: `${pagePrefix}: select date`,
      });
    }
  }, [date]);

  const isAdvanceOption = createIsAdvanceOptionFilter(eligibility, voucher, MoneyFormatter);
  const dateStrings = voucher.options.advance.terms.options
    .filter(option => isAdvanceOption(option))
    .map(option => option.term);
  const advanceDetails = getAdvanceDetails(voucher.options.advance.description);

  const dates = dateStrings.map(dateString => stringToDate(dateString));
  return (
    <AnalyticsPageContext.Provider value={ analyticsName }>
      <div className='advance-date-details v-container'>
        <h3>When would you like to pay?</h3>
        <div className='wrapper'>
          <DatePicker
            availableDates={ dates }
            selected={ date }
            setSelected={
              value => {
                setAdvancing({
                  ...advancing,
                  date: value,
                });
              }
            }
          />
        </div>
        <footer className='v-container'>
          <h4>Please note:</h4>
          <p>
            {
              date
              && `We will charge your account on the ${date.toLocaleString('default', { day: 'numeric' })} ${date.toLocaleString('default', {
                month: 'short',
                year: 'numeric',
              })} and no additional fees will apply.`
            }
            {' '}
            {advanceDetails.latePayment}
          </p>
          <button
            className='main-button'
            disabled={ !date }
            onClick={ () => handleContinue() }
          >Continue</button>
        </footer>
      </div>
    </AnalyticsPageContext.Provider>
  );
}
