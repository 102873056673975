import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OverScrollHandlerContext, UpdateOverScrollHandlerContext } from '../services/context/OverScrollContext.jsx';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function OverScrollController({ slot }) {
  const [overScrollHandler, setOverScrollHandler] = useState(null);
  return (
    <OverScrollHandlerContext.Provider value={ overScrollHandler }>
      <UpdateOverScrollHandlerContext.Provider value={ setOverScrollHandler }>
        {slot}
      </UpdateOverScrollHandlerContext.Provider>
    </OverScrollHandlerContext.Provider>
  );
}

OverScrollController.propTypes = { slot: PropTypes.element };
