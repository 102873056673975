export const shuffle = array => {
  for (let currentIndex = array.length; currentIndex > 0; currentIndex--) {
    const randomIndex = Math.floor(Math.random() * currentIndex);
    [array[currentIndex - 1], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex - 1],
    ];
  }

  return array;
};
