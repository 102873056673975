import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PaymentAmountContext, UpdatePaymentAmountContext } from '../services/context/PaymentAmountContext.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The PaymentAmountController Component
 */
export default function PaymentAmountController({ slot }) {
  const [paymentAmount, setPaymentAmount] = useState('');

  return (
    <PaymentAmountContext.Provider value={ paymentAmount }>
      <UpdatePaymentAmountContext.Provider value={ setPaymentAmount }>
        { slot }
      </UpdatePaymentAmountContext.Provider>
    </PaymentAmountContext.Provider>
  );
}

PaymentAmountController.propTypes = { slot: PropTypes.element };
