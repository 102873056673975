import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SnackBarContext, SnackBarDuration, UpdateSnackBarContext } from '../services/context/SnackBarContext.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function SnackBarController({ slot }) {
  const [snackBar, setSnackBar] = useState(null);

  useEffect(() => {
    const timeout = snackBar ? setTimeout(() => setSnackBar(null), SnackBarDuration + 1000) : undefined;
    return () => clearTimeout(timeout);
  }, [snackBar]);

  return (
    <SnackBarContext.Provider value={ snackBar }>
      <UpdateSnackBarContext.Provider value={ setSnackBar }>
        {slot}
      </UpdateSnackBarContext.Provider>
    </SnackBarContext.Provider>
  );
}

SnackBarController.propTypes = { slot: PropTypes.element };
