import { Voucher, AdvanceOption } from './Constants/ObjectDefinitions.js';

/**
 * @param {Voucher} voucher - voucher to get term of
 * @param {Date} date - chosen date
 * @returns {AdvanceOption} - the related term
 */
export const getTerm = (voucher, date) => {
  const today = new Date(Date.now());
  const diff = date.getTime() - today.getTime();
  const days = Math.ceil(diff / (1000 * 3600 * 24));
  return voucher.options.advance.terms.options.find(opt => (opt.term === `${days} days`));
};
