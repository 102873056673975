import React from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { withProfiler } from '@sentry/react';

/**
 * @returns {JSX.Element} 404 Page
 */
function NotFound() {
  const navigateTo = useNavigate();
  return (
    <>
      <div />
      <button
        className='main-button'
        onClick={ () => navigateTo(ROUTES.HOME) }
      >
        E.T. go home
      </button>
    </>
  );
}

export default withProfiler(NotFound);
