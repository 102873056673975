import React from 'react';
import './VendorCard.css';
import PropTypeShapes from '../../../services/Constants/PropTypeShapes.jsx';
import PropTypes from 'prop-types';
import { Vendor } from '../../../services/Constants/ObjectDefinitions.js';
import useVoucherProcessor from '../../../services/hooks/useVoucherProcessor.js';
import { captureMessage } from '@sentry/react';

const getLogo = vendor => (vendor ? (<img
  className='logo'
  onError={
    event => {
      captureMessage(`Failed to load image: ${event.currentTarget.src}`, 'warning');
      event.currentTarget.parentElement.style.backgroundImage = `linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 37.62%), url(${vendor.backgroundUrl})`;
      event.currentTarget.src = vendor.iconUrl;
    }
  }
  src={ vendor.iconUrl }
  alt={ vendor.name }
/>)
  : <div className='logo' />);

const getExtra = vendor => {
  const { getBestValueVoucher, getBuyNowPercentValue } = useVoucherProcessor();
  const comingSoon = vendor?.vouchers?.length === 0;
  const needExtra = vendor?.vouchers?.length > 0;
  const extra = needExtra && getBuyNowPercentValue(vendor.vouchers.reduce((accumulator, element) => getBestValueVoucher(accumulator, element)));
  return (comingSoon || extra)
    ? <p className='extra-info'>{comingSoon ? 'Coming Soon' : `${Math.floor(extra)}% extra`}</p>
    : null;
};

/**
 * @param {object} props - the props of the component
 * @param {Vendor} props.vendor - the vendor to display
 * @param {boolean} props.hideInfo - whether to hide info or not
 * @returns {JSX.Element} VendorCard Component
 */
export default function VendorCard({ vendor, hideInfo }) {
  const extra = !hideInfo ? getExtra(vendor) : null;
  const style = vendor ? { backgroundImage: `${hideInfo ? '' : 'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 37.62%), '}url(${vendor.backgroundUrl})` } : undefined;
  const logo = getLogo(vendor);
  return (
    <figure
      className={ `vendor-card v-container-h${vendor ? '' : ' empty skeleton-loader'}` }
      style={ style }
    >
      {logo}
      {extra}
      {
        !hideInfo
          ? <figcaption className='vendor-name'>{ vendor ? vendor.name : ''}</figcaption>
          : null
      }
    </figure>
  );
}

VendorCard.propTypes = {
  vendor: PropTypeShapes.vendor,
  hideInfo: PropTypes.bool,
};
