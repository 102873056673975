import React, { useContext, useState } from 'react';
import './MoneyInput.css';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import InputInfoIcon from '../../assets/InputInfoIcon.jsx';
import PropTypes from 'prop-types';

/**
 * @param {object} props the props of the component
 * @param {string | number} props.value - current value of the input
 * @param {Function} props.setValue - function to change current value
 * @param {number} props.maxValue - max value that is allowed (inclusive)
 * @param {number} props.minValue - min value that is allowed (inclusive)
 * @param {boolean} props.touched - whether the component has been touched before
 * @param {Function} props.setTouched - function to update when touched
 * @returns {JSX.Element} MoneyInput Component
 */
export default function MoneyInput({ value, setValue, maxValue, minValue, touched, setTouched }) {
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const [isFocus, setIsFocus] = useState(false);
  const processBlur = () => {
    setIsFocus(false);
    setValue(prevState => {
      if (!prevState) {
        return '';
      }

      const floated = Number.parseFloat(prevState);

      const maxed = Math.min(floated, maxValue);
      return maxed.toFixed(2);
    });
  };

  const showWarning = touched && !isFocus && value < minValue;

  return (
    <div className='money-input v-container'>
      <div className={ `wrapper v-container ${showWarning && 'warning'}` }>
        <input
          onBlur={ () => processBlur() }
          onFocus={
            () => {
              setIsFocus(true);
              setTouched(true);
            }
          }
          type='number'
          value={ value }
          onChange={ event => setValue(event.target.value) }
          placeholder='0.00'
          step='.01'
          min={ minValue }
          max={ maxValue }
        />
        <h3>{MoneyFormatter.format(MoneyFormatter.get(minValue)).at(0)}</h3>
      </div>
      <section className={ `info container-v ${showWarning && 'warning'}` }>
        <InputInfoIcon />
        {
          showWarning
            ? <p>Please enter an amount more than {MoneyFormatter.format(MoneyFormatter.get(minValue))}</p>
            : <p>Enter an amount up to {MoneyFormatter.format(MoneyFormatter.get(maxValue))}</p>
        }
      </section>
    </div>
  );
}

MoneyInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  setValue: PropTypes.func,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  touched: PropTypes.bool,
  setTouched: PropTypes.func,
};
