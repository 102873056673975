import React, { useContext, useState, useEffect } from 'react';
import DualButtons from '../DualButtons/DualButtons.jsx';
import InputWithValidation from '../InputWithValidation/InputWithValidation.jsx';
import { emailRegex } from '../../services/Constants/RegexCollection.js';
import { UpdatePopupContext, UpdateAnalyticsPopupContext } from '../../services/context/PopupContext.js';
import PropTypes from 'prop-types';
import { UserDetails } from '../../services/Constants/ObjectDefinitions.js';
import { UpdateUserDetailsContext, UserDetailsContext } from '../../services/context/UserDetailsContext.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';

/**
 * @callback onCapture
 * @param {UserDetails} userDetails - updatedUserDetails from capture
 */

/**
 * @param {object} props - props of the component
 * @param {onCapture} props.onCapture - on email capture handler
 * @returns {Element} InvoiceEmailCapture component
 */
export default function InvoiceEmailCapture({ onCapture }) {
  const emailMaxLength = 100;
  const setPopup = useContext(UpdatePopupContext);
  const userDetails = useContext(UserDetailsContext);
  const setUserDetails = useContext(UpdateUserDetailsContext);
  const [email, setEmail] = useState(userDetails.email ?? '');
  const [initialEmail] = useState(email);
  const [warning, setWarning] = useState(null);
  const pageName = useContext(AnalyticsPageContext);
  const analyticsPageName = `${pageName}: enter invoice email`;
  const setCrossIconAnalytic = useContext(UpdateAnalyticsPopupContext);

  const popupCloseAnalytics = {
    ...defaultAnalyticsVariables,
    event_name: [events.interaction],
    page_name: analyticsPageName,
    link_id: `${pagePrefix}: close`,
  };

  useEffect(() => {
    window.utag?.view({
      ...defaultAnalyticsVariables,
      page_name: analyticsPageName,
      event_name: [events.pageView],
    });
    setCrossIconAnalytic(popupCloseAnalytics);
  }, []);

  const handleEmailValidationAnalytics = errorName => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsPageName,
      event_name: [events.formError],
      link_id: `${pagePrefix}: error`,
      event_error_name: errorName,
      event_error_code: 'N/A',
      event_error_type: 'user error',
    });
  };

  const onContinueClick = () => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      event_name: [events.interaction],
      page_name: analyticsPageName,
      link_id: `${pagePrefix}: continue`,
    });
  };

  const onCloseClick = () => {
    window.utag?.link(popupCloseAnalytics);
  };

  return (
    <>
      <h3>Please {initialEmail ? 'confirm' : 'enter'} your email address</h3>
      <p>To send a tax invoice, we need your email. Your data is safe with us, we won’t spam you. </p>
      <InputWithValidation
        warning={ warning }
        value={ email }
        onChange={
          event => {
            setEmail(event.target.value.slice(0, emailMaxLength));
          }
        }
        onBlur={
          () => {
            if (!emailRegex.test(email)) {
              const errorName = 'Please enter a valid email address';
              setWarning(errorName);
              handleEmailValidationAnalytics(errorName);
            } else setWarning(null);
          }
        }
        placeholder={ 'Please enter your email address' }
      />
      <DualButtons
        primaryDisabled={ !email || warning }
        primaryText={ 'Continue' }
        primaryOnClick={
          async () => {
            setUserDetails(prevState => ({
              ...prevState,
              email,
            }));
            onCapture({
              ...userDetails,
              email,
            });
            setPopup(null);
            onContinueClick();
          }
        }
        secondaryText={ 'Close' }
        secondaryOnClick={
          async () => {
            setPopup(null);
            onCloseClick();
          }
        }
      />
    </>
  );
}

InvoiceEmailCapture.propTypes = { onCapture: PropTypes.func };
