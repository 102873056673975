import React, { createRef, useContext } from 'react';
import VendorCard from '../Vendor/Card/VendorCard.jsx';
import { getVoucherVendor } from '../../services/ReverseHierarchySearch.js';
import { CategoriesContext } from '../../services/context/CategoriesContext.js';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import PropTypeShapes from '../../services/Constants/PropTypeShapes.jsx';
import PropTypes from 'prop-types';
import { Voucher } from '../../services/Constants/ObjectDefinitions.js';
import InfoIcon from '../../assets/InfoIcon.jsx';

/**
 *
 * @param {object} props - the props of the component
 * @param {Voucher} props.voucher - the voucher to display
 * @param {JSX.Element} props.actionItem - the action item element to display
 * @param {Function} [props.onClick] - handler for on click of the upsellVoucher
 * @returns {JSX.Element} UpsellVoucher Component
 */
export default function CrossSellVoucher({ voucher, actionItem, onClick }) {
  const categories = useContext(CategoriesContext);
  const actionItemRef = createRef();
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const vendor = getVoucherVendor(categories, voucher);
  const value = MoneyFormatter.format(MoneyFormatter.getValue(voucher.options.buyNow.value));
  const cost = MoneyFormatter.format(MoneyFormatter.getValue(voucher.cost));
  return (
    <div
      className='product-voucher simplified v-container'
      onClick={ event => { if (!actionItemRef.current.contains(event.target)) onClick?.(event); } }
    >
      <VendorCard
        vendor={
          {
            backgroundUrl: voucher.productUrl,
            iconUrl: getVoucherVendor(categories, voucher)?.iconUrl,
          }
        }
        hideInfo={ true }
      />
      <section className='info v-container'>
        <header className='container-v'>
          <h3>{getVoucherVendor(categories, voucher)?.name}</h3>
          <h3>{MoneyFormatter.format(MoneyFormatter.getValue(voucher.cost))}</h3>
        </header>
        <div className='details'>
          <p>
            {`Buy a voucher worth ${value} from ${vendor.name} for only ${cost}`}
          </p>
          <div className='icons container-v'>
            { onClick ? <InfoIcon /> : <div /> }
            {
              actionItem
              && <div
                className='action-item'
                ref={ actionItemRef }
                onClick={ event => event.preventDefault() }
              >{actionItem}</div>
            }
          </div>
        </div>
      </section>
    </div>
  );
}

CrossSellVoucher.propTypes = {
  voucher: PropTypeShapes.voucher,
  actionItem: PropTypes.element,
  onClick: PropTypes.func,
};
