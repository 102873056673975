import React from 'react';
import './InputWithValidation.css';
import InputWithPlaceholder from '../InputWithPlaceholder/InputWithPlaceholder.jsx';
import WarningIcon from '../../assets/WarningIcon.jsx';
import PropTypes from 'prop-types';

/**
 * @param {object} props - props of the component
 * @param {string} props.value - state controlled value of the input
 * @param {Function} props.onChange - on input state change handler
 * @param {string} props.warning - warning text for invalid input
 * @param {string} props.placeholder - placeholder text for input
 * @param {Function} props.onBlur - on blur handler
 * @returns {JSX.Element} InputWithValidation component
 */
export default function InputWithValidation({ value, onChange, warning, placeholder, onBlur }) {
  return (
    <div className={ `input-with-validation${warning ? ' warning' : ''}` }>
      <InputWithPlaceholder
        value={ value }
        onChange={ onChange }
        onBlur={ onBlur }
        placeholder={ placeholder }
      />
      {warning && <p className='container-v'><WarningIcon />{warning}</p>}
    </div>
  );
}

InputWithValidation.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  warning: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
};
