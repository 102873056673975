import React from 'react';
import './RadioButton.css';
import PropTypes from 'prop-types';

/**
 *
 * @param {object} props - the props of the component
 * @param {boolean} props.selected - Whether the RadioButton is currently selected
 * @param {Function} props.onClick - What to do onClick of the button
 * @returns {JSX.Element} RadioButton Component
 */
export default function RadioButton({ selected, onClick }) {
  return (
    <svg
      className={ `radio-button${selected ? ' selected' : ''}` }
      tabIndex={ 0 }
      onKeyDown={ () => onClick && onClick() }
      onClick={ () => onClick && onClick() }
      viewBox='0 0 12 12'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='6'
        cy='6'
        r='6'
      />
    </svg>

  );
}

RadioButton.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};
