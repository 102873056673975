import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home.jsx';
import CategoryPage from './pages/Category/CategoryPage.jsx';
import VendorPage from './pages/Vendor/VendorPage.jsx';
import CartPage from './pages/Cart/CartPage.jsx';
import MyVouchers from './pages/MyVouchers/MyVouchers.jsx';
import ROUTES from './services/Constants/GlobalRoutes.jsx';
import Redirect from './components/Redirect/Redirect.jsx';

/**
 * @returns {JSX.Element} AppRoutesWithNav Component
 */
export default function AppRoutesWithNav() {
  return (
    <Routes>
      <Route
        path={ ROUTES.HOME }
        element={ <Home /> }
      />

      <Route
        path={ ROUTES.CATEGORY.get() }
        element={ <CategoryPage /> }
      />

      <Route
        path={ ROUTES.VENDOR.get() }
        element={ <VendorPage /> }
      />

      <Route
        path={ ROUTES.CART }
        element={ <CartPage /> }
      />

      <Route
        path={ ROUTES.MY_VOUCHERS }
        element={ <MyVouchers /> }
      />
      <Route
        path='*'
        element={ <Redirect to={ ROUTES.NOT_FOUND } /> }
      />
    </Routes>
  );
}
