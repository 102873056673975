import React from 'react';
import './TopBack.css';
import BackArrow from '../../assets/BackArrow.jsx';
import { useNavigate } from 'react-router-dom';

/**
 * @returns {JSX.Element} TopBack Component
 */
export default function TopBack() {
  const navigateTo = useNavigate();
  return (
    <button
      className='top-back container-v'
      onClick={ () => navigateTo(-1) }
    >
      <BackArrow />
      Back
    </button>
  );
}
