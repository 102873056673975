import React, { useContext, useState } from 'react';
import './Advance.css';
import AdvanceInfo from './Info/AdvanceInfo.jsx';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import PropTypeShapes from '../../services/Constants/PropTypeShapes.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { Money } from '../../services/Constants/ObjectDefinitions.js';

/**
 *
 * @param {object} props - the props of the component
 * @param {Money} props.amountAvailable - the total money to display
 * @returns {JSX.Element} Advance Component
 */
export default function Advance({ amountAvailable }) {
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const [showInfo, setShowInfo] = useState(false);

  const analyticsPage = useContext(AnalyticsPageContext);

  const pageName = `${pagePrefix}: how it works`;
  const onClick = () => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsPage,
      event_name: [events.interaction],
      link_id: pageName,
    });
    setShowInfo(true);
  };

  return (
    <section className='advance container-v'>
      <header className='v-container'>
        <p>Buy now, pay later</p>
        <div className='amount container-v'>
          <h3>{MoneyFormatter.format(amountAvailable)}</h3>
          <p className='smaller'>funds available</p>
        </div>
      </header>
      <button onClick={ onClick }>
        Find out more
      </button>
      <AnalyticsPageContext.Provider value={ pageName }>
        {
          showInfo
          && <AdvanceInfo setShow={ setShowInfo } />
        }
      </AnalyticsPageContext.Provider>
    </section>
  );
}

Advance.propTypes = { amountAvailable: PropTypeShapes.money };
