import { createContext, Context, Dispatch, SetStateAction } from 'react';

/**
 * @type {Context<string | JSX.Element>}
 */
export const MaintenanceMessageContext = createContext(null);

/**
 * @type {Context<Dispatch<SetStateAction<string | JSX.Element>>>}
 */
export const UpdateMaintenanceMessageContext = createContext(def => def);
