import React, { useContext, useEffect, useState } from 'react';
import './CrossSell.css';
import VouchersDisplay from '../../components/Vouchers/VouchersDisplay.jsx';
import { CategoriesContext } from '../../services/context/CategoriesContext.js';
import { CartContext } from '../../services/context/CartContext.js';
import ProductVoucher from '../../components/ProductVoucher/ProductVoucher.jsx';
import AddToCartButtons from '../../components/AddToCart/AddToCartButtons.jsx';
import { getCrossSellVouchers } from '../../services/CrossSellLogic.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { getVendorCategory, getVoucherVendor } from '../../services/ReverseHierarchySearch.js';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import { cartMax } from '../../services/Constants/config.js';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import Redirect from '../../components/Redirect/Redirect.jsx';
import CrossSellVoucher from '../../components/CrossSellVoucher/CrossSellVoucher.jsx';
import Slider from '../../components/Slider/Slider.jsx';
import DualButtons from '../../components/DualButtons/DualButtons.jsx';
import { Voucher } from '../../services/Constants/ObjectDefinitions.js';

/**
 * @returns {JSX.Element} CrossSell Component
 */
export default function CrossSell() {
  const categories = useContext(CategoriesContext);
  const cart = useContext(CartContext);
  const navigateTo = useNavigate();
  const [currentVoucher, setCurrentVoucher] = useState(/** @type {Voucher} */null);
  const [crossSellVouchers] = useState(getCrossSellVouchers(categories, cart));
  const [showCrossSell] = useState(cart.length > 0 && categories && crossSellVouchers?.length > 0 && cart.length < cartMax);
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const analyticsName = `${pagePrefix}: cross sell`;

  useEffect(() => {
    if (showCrossSell) {
      window.utag?.view({
        ...defaultAnalyticsVariables,
        page_name: analyticsName,
        event_name: [
          events.pageView,
          events.productView,
        ],
        product_category: crossSellVouchers.map(voucher => getVendorCategory(categories, getVoucherVendor(categories, voucher))?.name),
        product_quantity: crossSellVouchers.map(() => 1),
        product_name: crossSellVouchers.map(voucher => voucher.name),
        product_price: crossSellVouchers.map(voucher => MoneyFormatter.getValue(voucher.cost).amount),
        product_id: crossSellVouchers.map(voucher => voucher.id),
        transaction_payment_type: 'buy now',
      });
    }
  }, []);

  if (!showCrossSell) {
    return <Redirect to={ ROUTES.CART } />;
  }

  const onClose = () => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.interaction],
      link_id: `${pagePrefix}: continue`,
    });
    navigateTo(ROUTES.CART);
  };

  return (
    <AnalyticsPageContext.Provider value={ analyticsName }>
      <div className='cross-sell v-container'>
        <VouchersDisplay
          title={
            <h3>
              Get more value with {crossSellVouchers.length === 1 ? 'this voucher' : 'these vouchers'}
            </h3>
          }
          vouchers={
            crossSellVouchers.map(voucher => (
              <CrossSellVoucher
                onClick={ () => setCurrentVoucher(voucher) }
                key={ voucher.id }
                voucher={ voucher }
                actionItem={ <AddToCartButtons voucher={ voucher } /> }
                descriptionType='buyNow'
              />
            ))
          }
        />

        <DualButtons
          primaryOnClick={ onClose }
          primaryText={ <>Continue</> }
          secondaryOnClick={ () => navigateTo(-1) }
          secondaryText={ <>Back</> }
        />

        {
          currentVoucher
          && <Slider
            onClickOutside={ () => setCurrentVoucher(null) }
            slot={
              <ProductVoucher
                descriptionType={ 'buyNow' }
                voucher={ currentVoucher }
                actionItem={ <AddToCartButtons voucher={ currentVoucher } /> }
              />
            }
          />
        }
      </div>
    </AnalyticsPageContext.Provider>
  );
}
