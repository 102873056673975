import React, { useContext } from 'react';
import './ExpandableCouponBottom.css';
import { MoneyFormatterContext } from '../../../services/context/MoneyFormatterContext.js';
import ArrowDown from '../../../assets/ArrowDown.jsx';
import PropTypeShapes from '../../../services/Constants/PropTypeShapes.jsx';
import { UpdateCurrentCouponContext } from '../../../services/context/CurrentCouponContext.js';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../services/Constants/GlobalRoutes.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../../services/context/AnalyticsPageContext.js';
import { Gift } from '../../../services/Constants/ObjectDefinitions.js';
import { ACTIVE_STATUSES } from '../../../services/ActiveStatuses.js';

/**
 * @param {object} props - the props of the component
 * @param {Gift} props.gift - the gift associated with the coupon
 * @returns {JSX.Element} Coupon Status Component
 */
function Status({ gift }) {
  return (
    <div className='status v-container-h'>
      <p className='redemption'>
        {ACTIVE_STATUSES.includes(gift.status) ? 'Active' : gift.status}
      </p>
      {gift?.isAdvance && <p className='advance container-v'>Advance</p>}
    </div>
  );
}

Status.propTypes = { gift: PropTypeShapes.gift };

/**
 * @param {object} props - the props of the component
 * @param {Gift} props.gift - the gift associated with the coupon
 * @returns {JSX.Element} ExpandableCouponBottom Component
 */
export default function ExpandableCouponBottom({ gift }) {
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const setCurrentCoupon = useContext(UpdateCurrentCouponContext);
  const navigateTo = useNavigate();
  const redemptionText = gift?.voucher?.redemption.type;
  const analyticsName = useContext(AnalyticsPageContext);
  const pageLinkAnalytics = {
    ...defaultAnalyticsVariables,
    page_name: analyticsName,
    event_name: [events.interaction],
  };
  const onViewCode = () => {
    window.utag?.link({
      ...pageLinkAnalytics,
      link_id: `${pagePrefix}: view code`,
    });
    setCurrentCoupon(gift);
    navigateTo(ROUTES.VOUCHER_INFO);
  };

  return (
    <div className='expandable-bottom v-container'>
      <header className='container-v'>
        <div className='v-container'>
          <h3>
            {MoneyFormatter.format(gift.balance)}
          </h3>

          <p className='extra-info smaller'>
            {redemptionText}
          </p>
        </div>

        <Status gift={ gift } />

        <button
          className={ 'container-v' }
          onClick={ onViewCode }
        >
          View code
          <ArrowDown />
        </button>
      </header>
    </div>
  );
}

ExpandableCouponBottom.propTypes = { gift: PropTypeShapes.gift };
