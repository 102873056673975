import { createContext, Context, Dispatch, SetStateAction } from 'react';

/**
 * @type {Context<string>}
 */
export const BearerTokenContext = createContext(undefined);

/**
 * @type {Context<Dispatch<SetStateAction<string>>>}
 */
export const UpdateBearerTokenContext = createContext(def => def);
