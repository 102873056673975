import React, { useContext, useEffect, useRef, useState } from 'react';
import { CartContext, UpdateCartContext } from '../services/context/CartContext.js';
import PropTypes from 'prop-types';
import { UpdateSnackBarContext } from '../services/context/SnackBarContext.js';
import CartIcon from '../assets/CartIcon.jsx';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function CartController({ slot }) {
  const [cartTimeout, setCartTimeout] = useState(localStorage.getItem('cartTimeout') && new Date(localStorage.getItem('cartTimeout')));
  const timeoutRef = useRef(null);
  const setSnackBar = useContext(UpdateSnackBarContext);
  const [cart, setCart] = useState(localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : []);
  const cartLifeMinutes = 30;

  const updateCart = updatedCart => {
    setCart(updatedCart);
    if (updatedCart.length > 0) {
      const timeout = new Date(Date.now());
      timeout.setMinutes(timeout.getMinutes() + cartLifeMinutes);
      setCartTimeout(timeout);
      try {
        localStorage.setItem('cartTimeout', timeout.toISOString());
        localStorage.setItem('cart', JSON.stringify(updatedCart));
      } catch { /* ignore */ }
    } else {
      setCartTimeout(null);
      localStorage.removeItem('cartTimeout');
      localStorage.removeItem('cart');
    }
  };

  useEffect(() => {
    if (cartTimeout) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        updateCart([]);
        localStorage.removeItem('cartTimeout');
        setSnackBar({
          icon: <CartIcon />,
          body: 'Your cart has expired',
        });
      }, cartTimeout - Date.now());
    } else {
      clearTimeout(timeoutRef.current);
    }
  }, [cartTimeout]);

  return (
    <CartContext.Provider value={ cart }>
      <UpdateCartContext.Provider value={ updateCart }>
        {slot}
      </UpdateCartContext.Provider>
    </CartContext.Provider>
  );
}

CartController.propTypes = { slot: PropTypes.element };
