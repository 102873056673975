import { createContext } from 'react';
import { Money } from '../Constants/ObjectDefinitions.js';

/**
 * @param {string} currency - international currency code
 * @returns {string} - associated South African Symbol
 */
export const getCurrencySymbol = currency => (0).toLocaleString(
  'en-ZA',
  {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
).replace(/\d/g, '').trim();

/**
 * @param {number} number - number to format
 * @returns {string} - formatted number with spaces between thousands, millions, etc.
 */
export const toFormattedString = number => {
  const [whole, decimal] = number.toFixed(2).split('.');

  return [
    [...whole].flatMap((element, index, arr) => (
      (arr.length - index) % 3 === 0 && index !== 0
        ? [
          '\u00A0', // non-breaking space
          element,
        ]
        : [element])).join(''),
    decimal,
  ].join('.');
};

export const MoneyFormatterContext = createContext({
  /**
   * @param {Money} currency - currency to format
   * @returns {string} - formatted currency
   */
  format: currency => `${getCurrencySymbol(currency.unit)}${toFormattedString(currency.amount)}`,
  /**
   * @param {Money} currency1 - currency to add
   * @param {Money} currency2 - currency to add
   * @returns {Money|undefined} - sum of currencies, (undefined if different currency unit)
   */
  add: (currency1, currency2) => {
    if (currency1.unit !== currency2.unit) {
      return;
    }

    return {
      amount: currency1.amount + currency2.amount,
      unit: currency1.unit,
    };
  },
  /**
   * @param {object} value - value to convert into Money
   * @returns {Money} - Monetary value
   */
  getValue: value => {
    const [unit, amount] = Object.entries(value)
      .find(Boolean);
    return {
      unit,
      amount,
    };
  },
  /**
   *
   * @param {Money} subtrahend - Money subtracted
   * @param {Money} minuend - Money subtracted from
   * @returns {Money|undefined} - difference between the values (undefined if different currency unit)
   */
  subtract: (subtrahend, minuend) => {
    if (subtrahend.unit !== minuend.unit) {
      return;
    }

    return {
      amount: minuend.amount - subtrahend.amount,
      unit: subtrahend.unit,
    };
  },
  /**
   * @param {number} amount - numeric amount to convert to Money
   * @returns {Money} - the Money with amount
   */
  get: amount => ({
    unit: 'ZAR',
    amount,
  }),
});
