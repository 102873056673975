import React from 'react';
import './CategoryVouchers.css';
import ProductVoucher from '../../ProductVoucher/ProductVoucher.jsx';
import AddToCartButtons from '../../AddToCart/AddToCartButtons.jsx';
import PropTypeShapes from '../../../services/Constants/PropTypeShapes.jsx';
import VouchersDisplay from '../../Vouchers/VouchersDisplay.jsx';
import { Category } from '../../../services/Constants/ObjectDefinitions.js';
import StockStatus from '../../../services/Constants/StockStatus.js';
import CrossSellVoucher from '../../CrossSellVoucher/CrossSellVoucher.jsx';

/**
 * @param {object} props - the props of the component
 * @param {Category} props.category - the category to display
 * @returns {JSX.Element} CategoryVouchers Component
 */
export default function CategoryVouchers({ category }) {
  const isEmpty = !category;
  const skeletonVouchers = [
    0,
    1,
    2,
  ];
  return (
    <VouchersDisplay
      title={
        <h3 className={ `category-vouchers-header ${isEmpty ? 'empty skeleton-loader' : ''}` }>
          {isEmpty ? null : `${category.name} vouchers`}
        </h3>
      }
      vouchers={
        isEmpty ? [] : category.vendors
          .filter(vendor => vendor.vouchers?.some(voucher => voucher.stock.status === StockStatus.inStock))
          .map(vendor => (
            <CrossSellVoucher
              key={ vendor.id }
              voucher={ vendor.vouchers.find(voucher => voucher.stock.status === StockStatus.inStock) }
              actionItem={ <AddToCartButtons voucher={ vendor.vouchers.find(voucher => voucher.stock.status === StockStatus.inStock) } /> }
              descriptionType='buyNow'
            />
          ))
      }
      isLoading={ isEmpty }
      onLoading={ skeletonVouchers.map(number => <ProductVoucher key={ number } />) }
    />
  );
}

CategoryVouchers.propTypes = { category: PropTypeShapes.category };
