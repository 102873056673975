import { createContext, Context, Dispatch, SetStateAction } from 'react';
import { Voucher } from '../Constants/ObjectDefinitions.js';

/**
 * @type {Context<{voucher: Voucher, date: Date}>}
 */
export const AdvanceContext = createContext(undefined);

/**
 * @type {Context<Dispatch<SetStateAction<{voucher: Voucher, date: Date}>>>}
 */
export const UpdateAdvanceContext = createContext(def => def);
