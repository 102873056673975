import React, { useContext } from 'react';
import './ProductVoucher.css';
import VendorCard from '../Vendor/Card/VendorCard.jsx';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import PropTypeShapes from '../../services/Constants/PropTypeShapes.jsx';
import PropTypes from 'prop-types';
import { Voucher } from '../../services/Constants/ObjectDefinitions.js';
import { getVoucherVendor } from '../../services/ReverseHierarchySearch.js';
import { CategoriesContext } from '../../services/context/CategoriesContext.js';

/**
 *
 * @param {object} props - the props of the component
 * @param {Voucher} props.voucher - the voucher to display
 * @param {JSX.Element} props.actionItem - the action item element to display
 * @param {string} props.descriptionType - which description to display
 * @returns {JSX.Element} ProductVoucher Component
 */
export default function ProductVoucher({ voucher, actionItem, descriptionType }) {
  const categories = useContext(CategoriesContext);
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const skeletonLoader = 'skeleton-loader';
  const isEmpty = !voucher;
  return (
    <div className={ `product-voucher v-container${isEmpty ? ' empty' : ''}` }>
      <VendorCard
        vendor={
          isEmpty ? null : {
            backgroundUrl: voucher.productUrl,
            iconUrl: getVoucherVendor(categories, voucher)?.iconUrl,
          }
        }
        hideInfo={ true }
      />
      <section className='info v-container'>
        <header className='container-v'>
          <h3 className={ isEmpty ? skeletonLoader : undefined }>
            {isEmpty ? null : voucher.name }
          </h3>
          <h3 className={ isEmpty ? skeletonLoader : undefined }>
            {isEmpty ? null : MoneyFormatter.format(MoneyFormatter.getValue(voucher.cost))}
          </h3>
        </header>
        <p className={ isEmpty ? skeletonLoader : undefined }>
          {isEmpty ? null : voucher.options[descriptionType].description}
        </p>
        {
          actionItem
          && <div className='action-item'>{actionItem}</div>
        }
      </section>
    </div>
  );
}

ProductVoucher.propTypes = {
  voucher: PropTypeShapes.voucher,
  actionItem: PropTypes.element,
  descriptionType: PropTypes.oneOf([
    'advance',
    'buyNow',
  ]),
};
