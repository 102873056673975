import React, { useEffect, useRef } from 'react';
import AppRoutesWithNav from '../../AppRoutesWithNav.jsx';
import BottomNav from '../BottomNav/BottomNav.jsx';
import { useLocation } from 'react-router-dom';
import useOverScroll from '../../services/hooks/useOverScroll.js';

/**
 * @returns {JSX.Element} MainContent Component
 */
export default function MainContent() {
  const content = useRef(null);
  const { pathname } = useLocation();
  const { onTouchEnd, onTouchMove, getStyle, onTouchStart } = useOverScroll('1rem');
  useEffect(() => {
    content.current?.scrollTo(0, 0);
  }, [pathname]);
  return (

    <>
      <main
        ref={ content }
        style={ getStyle() }
        className='content v-container'
        onTouchStart={ onTouchStart }
        onTouchMove={ onTouchMove }
        onTouchEnd={ onTouchEnd }
      >
        <AppRoutesWithNav />
      </main>
      <BottomNav />
    </>
  );
}
