import React, { useContext } from 'react';
import { MoneyFormatterContext } from '../services/context/MoneyFormatterContext.js';
import { ConfigContext } from '../services/context/ConfigContext.js';
import PropTypes from 'prop-types';
import { Money } from '../services/Constants/ObjectDefinitions.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function MoneyFormatterController({ slot }) {
  const config = useContext(ConfigContext);
  const currencyCode = config.external.magento.normalCurrency;
  const moneyFormatter = {
    ...useContext(MoneyFormatterContext),
    /**
     * @param {object} value - object of currency codes to monetary values
     * @returns {Money} - Money with currency code matching config
     */
    getValue: value => {
      const [unit, amount] = Object.entries(value)
        .find(([code]) => code === currencyCode);
      return {
        unit,
        amount,
      };
    },
    /**
     * @param {number} amount - numeric amount to convert to Money
     * @returns {Money} - the Money of that amount with unit from config
     */
    get: amount => ({
      unit: currencyCode,
      amount,
    }),
  };
  return (
    <MoneyFormatterContext.Provider value={ moneyFormatter }>
      {slot}
    </MoneyFormatterContext.Provider>
  );
}

MoneyFormatterController.propTypes = { slot: PropTypes.element };
