import React, { useEffect, useState } from 'react';
import PayAdvanceInputs from '../../components/PayAdvanceInputs/PayAdvanceInputs.jsx';
import PayAdvanceDetails from '../../components/PayAdvanceDetails/PayAdvanceDetails.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { PaymentTouchedContext, UpdatePaymentTouchedContext } from '../../services/context/PaymentAmountContext.js';
import { withProfiler } from '@sentry/react';

/**
 * @returns {JSX.Element} PayAdvancePage Component
 */
function PayAdvancePage() {
  const [touched, setTouched] = useState(false);
  const analyticsName = `${pagePrefix}: pay advance`;
  useEffect(() => {
    window.utag?.view({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.pageView],
    });
  }, []);

  return (
    <AnalyticsPageContext.Provider value={ analyticsName }>
      <PaymentTouchedContext.Provider value={ touched }>
        <UpdatePaymentTouchedContext.Provider value={ setTouched }>
          <PayAdvanceInputs />
          <PayAdvanceDetails />
        </UpdatePaymentTouchedContext.Provider>
      </PaymentTouchedContext.Provider>
    </AnalyticsPageContext.Provider>
  );
}

export default withProfiler(PayAdvancePage);
