import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CurrentCouponContext,
  CurrentCouponLoanDetailsContext,
  UpdateCurrentCouponContext,
  UpdateCurrentCouponLoanDetailsContext,
} from '../services/context/CurrentCouponContext.js';
import { Gift } from '../services/Constants/ObjectDefinitions.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function CurrentCouponController({ slot }) {
  const [currentCoupon, setCurrentCoupon] = useState(/** @type {Gift} */null);
  const [loanDetails, setLoanDetails] = useState(/** @type {{outstanding: number, fees: number, balance: number}} */null);

  return (
    <CurrentCouponContext.Provider value={ currentCoupon }>
      <CurrentCouponLoanDetailsContext.Provider value={ loanDetails }>
        <UpdateCurrentCouponContext.Provider value={ setCurrentCoupon }>
          <UpdateCurrentCouponLoanDetailsContext.Provider value={ setLoanDetails }>
            {slot}
          </UpdateCurrentCouponLoanDetailsContext.Provider>
        </UpdateCurrentCouponContext.Provider>
      </CurrentCouponLoanDetailsContext.Provider>
    </CurrentCouponContext.Provider>
  );
}

CurrentCouponController.propTypes = { slot: PropTypes.element };
