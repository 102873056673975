import React, { ChangeEventHandler, FocusEventHandler } from 'react';
import './InputWithPlaceholder.css';
import PropTypes from 'prop-types';

/**
 *
 * @param {object} props - the props of the component
 * @param {string} props.value - the state controlled value of the input
 * @param {ChangeEventHandler<HTMLInputElement>} props.onChange - the handler for input state change
 * @param {string} props.placeholder - input placeholder text
 * @param {FocusEventHandler<HTMLInputElement>} props.onBlur - handler for input blur
 * @returns {JSX.Element} InputWithPlaceholder component
 */
export default function InputWithPlaceholder({ value, onChange, placeholder, onBlur }) {
  return (
    <div className='input-with-placeholder v-container'>
      <input
        type='text'
        value={ value }
        onChange={ onChange }
        onBlur={ onBlur }
        required
      />
      <label className='container-v'>{placeholder}</label>
    </div>
  );
}

InputWithPlaceholder.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
};
