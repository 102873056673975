import React, { useContext } from 'react';
import './CheckoutButton.css';
import { CartContext } from '../../services/context/CartContext.js';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';

/**
 * @returns {JSX.Element} CheckoutButton component
 */
export default function CheckoutButton() {
  const cart = useContext(CartContext);
  const navigateTo = useNavigate();
  return (
    <button
      className='main-button checkout-button'
      onClick={ () => navigateTo(ROUTES.CROSS_SELL) }
    >
      Checkout ({cart.length})
    </button>
  );
}
