import React from 'react';
import './NavItem.css';
import PropTypes from 'prop-types';

/**
 *
 * @param {object} props - the props of the component
 * @param {JSX.Element} props.icon - the icon element to display
 * @param {string} props.name - the name of the element
 * @param {string} props.route - the route associated with the NavItem
 * @returns {JSX.Element} NavItem Component
 */
export default function NavItem({ icon, name, route }) {
  const isSelected = window.location.pathname === route?.replace(' ', '%20');
  return (
    <div className={ `nav-item v-container-h${isSelected ? ' selected' : ''}` }>
      {icon}
      <p className='route'>{name}</p>
    </div>
  );
}

NavItem.propTypes = {
  icon: PropTypes.element,
  name: PropTypes.string,
  route: PropTypes.string,
};
