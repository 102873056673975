import React, { useContext, useRef } from 'react';
import './Coupon.css';
import PropTypes from 'prop-types';
import PropTypeShapes from '../../services/Constants/PropTypeShapes.jsx';
import { Gift } from '../../services/Constants/ObjectDefinitions.js';
import { getVoucherVendor } from '../../services/ReverseHierarchySearch.js';
import { CategoriesContext } from '../../services/context/CategoriesContext.js';
import DefaultCouponIcon from '../../assets/DefaultCouponIcon.jsx';
import useTile3D from '../../services/hooks/useTile3D.js';

const getCouponImage = gift => {
  if (!gift) return <div />;
  const categories = useContext(CategoriesContext);
  return gift.voucher
    ? (
      <div
        style={ { backgroundImage: `url(${getVoucherVendor(categories, gift.voucher)?.iconUrl})` } }
      />)
    : (
      <>
        <DefaultCouponIcon />
        <h3 className='container-vh'>{gift.name}</h3>
      </>
    );
};

/**
 *
 * @param {object} props - the props of the component
 * @param {Gift} props.gift - the gift associated with the coupon
 * @param {JSX.Element} props.bottom - the element to display at the bottom of the coupon
 * @param {string} props.id - the id of the coupon
 * @returns {JSX.Element} Coupon Component
 */
export default function Coupon({ gift, bottom, id }) {
  const backRef = useRef(/** @type {HTMLDivElement} */null);
  const { onTouchEnd, onTouchMove, onTouchStart } = useTile3D(backRef);
  const image = getCouponImage(gift);
  return (
    <div style={ { perspective: '1000px' } }>
      <div
        onTouchStart={ onTouchStart }
        onTouchMove={ onTouchMove }
        onTouchEnd={ onTouchEnd }
        id={ id }
        className={ `coupon v-container${gift ? '' : ' empty skeleton-loader'} tile-3d` }
      >
        <figure style={ gift?.voucher?.productUrl ? { backgroundImage: `url(${gift.voucher?.productUrl})` } : null }>
          {image}
        </figure>
        <main>
          {bottom}
        </main>
      </div>
      <div
        className='coupon back tile-3d'
        ref={ backRef }
      />
    </div>
  );
}

Coupon.propTypes = {
  bottom: PropTypes.element,
  gift: PropTypeShapes.gift,
  voucher: PropTypeShapes.voucher,
  id: PropTypes.string,
};
