import React from 'react';
import './KeyValueList.css';
import PropTypes from 'prop-types';

/**
 * @param {object} props - the props of the component
 * @param {object} props.object - the object to display as a key-value list
 * @returns {JSX.Element} KeyValue List Component
 */
export default function KeyValueList({ object }) {
  const mapped = [];
  for (const key in object) {
    mapped.push(<li
      key={ key }
      className='container-v'
    >
      <h5>{key}:</h5>
      <p>{object[key]}</p>
    </li>);
  }

  return (
    <ul className='key-value-list v-container'>
      {mapped}
    </ul>
  );
}

KeyValueList.propTypes = { object: PropTypes.object };
