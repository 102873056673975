import React, { useEffect, useState } from 'react';
import { UpdateUserDetailsContext, UserDetailsContext } from '../services/context/UserDetailsContext.js';
import PropTypes from 'prop-types';
import useQuery from '../services/hooks/useQuery.js';
import { setTag } from '@sentry/react';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function UserDetailsController({ slot }) {
  const queryParams = useQuery();
  const [userDetails, setUserDetails] = useState({
    msisdn: null,
    msisdnDash: null,
    email: null,
    userId: queryParams.get('userId') ?? '216610000001904885405',
    subscriberId: null,
    vsp: null,
  });

  useEffect(() => {
    if (userDetails.msisdn) setTag('msisdn', userDetails.msisdn);
  }, [userDetails]);

  return (
    <UserDetailsContext.Provider value={ userDetails }>
      <UpdateUserDetailsContext.Provider value={ setUserDetails }>
        {slot}
      </UpdateUserDetailsContext.Provider>
    </UserDetailsContext.Provider>
  );
}

UserDetailsController.propTypes = { slot: PropTypes.element };
