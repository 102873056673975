import React, { useContext } from 'react';
import './RocketBody.css';
import RocketContinue from '../RocketContinue/RocketContinue.jsx';
import { RocketContext } from '../../services/context/RocketContext.js';
import PropTypes from 'prop-types';

/**
 * @param {object} props - the props of the component
 * @param {boolean} props.isFailure - whether successful or not
 * @returns {JSX.Element} SuccessBody Component
 */
export default function RocketBody({ isFailure }) {
  const { body } = useContext(RocketContext);
  return (
    <section className='success-body v-container'>
      {body}
      <RocketContinue isFailure={ isFailure } />
    </section>
  );
}

RocketBody.propTypes = { isFailure: PropTypes.bool };
