import React from 'react';
import './CategoryIcon.css';
import PropTypeShapes from '../../../services/Constants/PropTypeShapes.jsx';
import { Category } from '../../../services/Constants/ObjectDefinitions.js';

/**
 *
 * @param {object} props - the props of the component
 * @param {Category} props.category - the Category to display
 * @returns {JSX.Element} CategoryIcon Component
 */
export default function CategoryIcon({ category }) {
  return (
    <figure className={ `category${category ? '' : ' empty'}` }>
      <div
        className={ `icon${category ? '' : ' skeleton-loader'}` }
        style={ category ? { backgroundImage: `url(${category.iconUrl})` } : {} }
      >
      </div>
      <figcaption className={ category ? '' : 'skeleton-loader' }>{category ? category.name : ''}</figcaption>
    </figure>
  );
}

CategoryIcon.propTypes = { category: PropTypeShapes.category };
