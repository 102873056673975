import React, { useContext, useState } from 'react';
import './CartDetails.css';
import Voucher from '../../Voucher/Voucher.jsx';
import { MoneyFormatterContext } from '../../../services/context/MoneyFormatterContext.js';
import EmptyCart from '../../../assets/EmptyCart.jsx';
import PropTypeShapes from '../../../services/Constants/PropTypeShapes.jsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import AddToCartButtons from '../../AddToCart/AddToCartButtons.jsx';
import Slider from '../../Slider/Slider.jsx';
import ProductVoucher from '../../ProductVoucher/ProductVoucher.jsx';
import { CartContext } from '../../../services/context/CartContext.js';
import VouchersDisplay from '../../Vouchers/VouchersDisplay.jsx';
import ROUTES from '../../../services/Constants/GlobalRoutes.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../../services/context/AnalyticsPageContext.js';
import { getVoucherVendor } from '../../../services/ReverseHierarchySearch.js';
import { CategoriesContext } from '../../../services/context/CategoriesContext.js';
import EmptyIconButton from '../../EmptyIconButton/EmptyIconButton.jsx';

/**
 * @returns {JSX.Element} CartDetails Component
 */
export default function CartDetails() {
  const navigateTo = useNavigate();
  const cart = useContext(CartContext);
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const uniqueVouchers = cart
    .filter((value, index) => index === cart
      .findIndex(t => value.id === t.id));
  const categories = useContext(CategoriesContext);
  const [currentVoucher, setCurrentVoucher] = useState(/** @type {Voucher} */null);

  const analyticsName = useContext(AnalyticsPageContext);
  const emptyClick = () => {
    navigateTo(ROUTES.HOME);

    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.interaction],
      link_id: `${pagePrefix}: start shopping`,
    });
  };

  return (
    <section className='cart-details v-container'>
      <VouchersDisplay
        title={
          <header className='cart-amount'>
            <h3>Cart</h3>
            <p>{cart.length} item{cart.length === 1 ? '' : 's'}</p>
          </header>
        }
        vouchers={
          uniqueVouchers
            .map(voucher => (
              <Voucher
                voucher={ voucher }
                onClick={ () => setCurrentVoucher(voucher) }
                key={ voucher.id }
                iconUrl={ getVoucherVendor(categories, voucher)?.iconUrl }
                title={ <em>{MoneyFormatter.format(MoneyFormatter.getValue(voucher.cost))}</em> }
                addedValue={
                  <>
                    Get
                    {' '}
                    {
                      MoneyFormatter.format(MoneyFormatter.subtract(
                        MoneyFormatter.getValue(voucher.cost),
                        MoneyFormatter.getValue(voucher.options.buyNow.value),
                      ))
                    }
                    {' '}
                    extra
                  </>
                }
                actionItem={ <AddToCartButtons voucher={ voucher } /> }
              />
            ))
        }
        onEmpty={
          <EmptyIconButton
            icon={ <EmptyCart /> }
            title={ 'Your cart is empty right now' }
            onClick={ emptyClick }
            buttonText={ 'Start Shopping' }
          />
        }
      />
      {
        currentVoucher
        && <Slider
          slot={
            <ProductVoucher
              voucher={ currentVoucher }
              actionItem={ <AddToCartButtons voucher={ currentVoucher } /> }
              descriptionType='buyNow'
            />
          }
          onClickOutside={ () => setCurrentVoucher(null) }
        />
      }
    </section>
  );
}

CartDetails.propTypes = {
  cart: PropTypeShapes.cart,
  setCart: PropTypes.func,
};
