import { createContext, Context, Dispatch, SetStateAction } from 'react';
import { RocketProperties } from '../Constants/ObjectDefinitions.js';

/**
 * @type {Context<RocketProperties>}
 */
export const RocketContext = createContext({
  analytics: null,
  body: null,
  header: null,
  onContinue: null,
});

/**
 * @type {Context<Dispatch<SetStateAction<RocketProperties>>>}
 */
export const UpdateRocketContext = createContext(({ analytics, body, header, onContinue }) => ({
  analytics,
  body,
  header,
  onContinue,
}));
