import React, { useContext } from 'react';
import './RocketCouponDisplay.css';
import Coupon from '../Coupon/Coupon.jsx';
import StubCouponBottom from '../CouponBottoms/Stub/StubCouponBottom.jsx';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import PropTypeShapes from '../../services/Constants/PropTypeShapes.jsx';
import { Voucher } from '../../services/Constants/ObjectDefinitions.js';
import PropTypes from 'prop-types';

/**
 * @param {object} props - the props of the component
 * @param {Voucher[]}props.cart - list of all bought vouchers
 * @param {boolean} props.isFailure - whether successful or not
 * @returns {JSX.Element} SuccessCouponDisplay component
 */
export default function RocketCouponDisplay({ cart, isFailure }) {
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const coupons = cart.map((voucher, index) => (
    <Coupon
      key={ index }
      gift={ { voucher } }
      bottom={
        <StubCouponBottom
          gift={
            {
              voucher,
              value: isFailure ? MoneyFormatter.getValue(voucher.cost) : MoneyFormatter.getValue(voucher.options.buyNow.value),
            }
          }
          isFailure={ isFailure }
        />
      }
    />
  ));
  return (
    <ul className='success-coupon-display v-container'>
      {coupons}
    </ul>
  );
}

RocketCouponDisplay.propTypes = {
  cart: PropTypeShapes.cart,
  isFailure: PropTypes.bool,
};
