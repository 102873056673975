import PropTypes from 'prop-types';

const money = PropTypes.shape({
  unit: PropTypes.string,
  amount: PropTypes.number,
});

const voucher = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  priority: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  productUrl: PropTypes.string,
  cost: money,
  value: PropTypes.shape({
    advance: money,
    normal: money,
  }),
  options: PropTypes.shape({
    buyNow: PropTypes.shape({
      payment: PropTypes.shape({
        prefix: PropTypes.number,
        code: PropTypes.number,
      }),
    }),
    advance: PropTypes.shape({
      payment: PropTypes.shape({
        prefix: PropTypes.number,
        code: PropTypes.number,
      }),
    }),
  }),
});

const cart = PropTypes.arrayOf(voucher);

const vendor = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  priority: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  name: PropTypes.string,
  iconUrl: PropTypes.string,
  backgroundUrl: PropTypes.string,
});

const category = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  iconUrl: PropTypes.string,
  priority: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  vendors: PropTypes.arrayOf(vendor),
});

const categories = PropTypes.arrayOf(category);

const eligibility = PropTypes.shape({
  eligibility: PropTypes.bool,
  value: money,
});

const gift = PropTypes.shape({
  voucher,
  value: money,
  redemptionCode: PropTypes.string,
  status: PropTypes.string,
  voucherId: PropTypes.string,
  expiryDate: PropTypes.string,
});

export default {
  money,
  voucher,
  cart,
  vendor,
  category,
  categories,
  eligibility,
  gift,
};
