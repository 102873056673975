import { useContext, useRef, useState } from 'react';
import { OverScrollHandlerContext } from '../context/OverScrollContext.jsx';

/**
 *
 * @param {string} [initialPadding='0'] - the initial box padding to add for scrolling
 * @returns {{
 * getStyle: Function,
 * onTouchMove: Function,
 * onTouchStart: Function}
 * } handlers to apply to the element to enable vertical over-scrolling
 */
export default function useOverScroll(initialPadding = '0') {
  const [yTouchStart, setYTouchStart] = useState(/** @type {number} */null);
  const [xTouchStart, setXTouchStart] = useState(/** @type {number} */null);
  const [dragStart, setDragStart] = useState(/** @type {number} */null);
  const [dragEnd, setDragEnd] = useState(/** @type {number} */null);
  const isHoriz = useRef(/** @type {boolean|null} */null);
  const eventTarget = useRef(/** @type {EventTarget} */null);
  const overScrollHandler = useContext(OverScrollHandlerContext);
  const overScrollHandlerMinDistance = 50;
  const { scrollTop, scrollHeight, clientHeight } = eventTarget.current ?? {};
  const isNearBottom = scrollTop + clientHeight >= scrollHeight - 5; // gives margin of error for scroll start
  const atTop = scrollTop === 0;
  const scrollingUp = dragEnd - dragStart > 0;

  const doTransform = () => {
    if (!scrollingUp && atTop) return false;
    if (scrollingUp && isNearBottom) return false;
    return (atTop || isNearBottom);
  };

  const getTransform = () => {
    if (!doTransform()) return 0;
    const distance = Math.abs(dragEnd - dragStart);
    if (!overScrollHandler || isNearBottom) return dragStart && dragEnd ? Math.log1p(distance) : 0;
    if (distance > overScrollHandlerMinDistance) return overScrollHandlerMinDistance + Math.log1p(distance - overScrollHandlerMinDistance);

    return distance;
  };

  const transform = getTransform();

  const onTouchEnd = () => {
    const distance = dragEnd - dragStart;
    if (atTop && distance > overScrollHandlerMinDistance) overScrollHandler?.();
    setDragStart(null);
    setDragEnd(null);
    setXTouchStart(null);
    setYTouchStart(null);
    isHoriz.current = null;
    eventTarget.current = null;
  };

  const onTouchStart = event => {
    eventTarget.current = event.currentTarget;
    setYTouchStart(event.targetTouches[0].clientY);
    setXTouchStart(event.targetTouches[0].clientX);
  };

  const checkNearEdges = event => {
    if (scrollHeight === clientHeight && !overScrollHandler) return; // no over-scroll on non-scrollable unless there is a handler
    if ((isNearBottom || atTop) && !dragStart) setDragStart(event.targetTouches[0].clientY);
    if (dragStart) setDragEnd(event.targetTouches[0].clientY);
    if (isNearBottom) event.currentTarget.scrollBy(0, 1); // scroll down to 'new' padding
  };

  const checkIfHoriz = event => {
    const vertDist = Math.abs(yTouchStart - event.targetTouches[0].clientY);
    const horizDist = Math.abs(xTouchStart - event.targetTouches[0].clientX);
    if (horizDist > vertDist && !dragStart) { // only check if horizontal swipe before we start over-scroll
      isHoriz.current = true;
    }

    return isHoriz.current;
  };

  const onTouchMove = event => {
    if (checkIfHoriz(event)) return;
    checkNearEdges(event);
  };

  const getStyle = () => {
    if (!transform) return { transition: 'padding .2s ease' };
    const transformStyle = `calc(${transform}px + ${initialPadding})`;
    if (scrollingUp) return { paddingTop: transformStyle };
    return { paddingBottom: transformStyle };
  };

  return {
    getStyle,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
}
