import React from 'react';
import './VendorHeader.css';
import VendorCard from '../Card/VendorCard.jsx';
import PropTypeShapes from '../../../services/Constants/PropTypeShapes.jsx';
import { Vendor } from '../../../services/Constants/ObjectDefinitions.js';

/**
 * @param {object} props - the props of the component
 * @param {Vendor} [props.vendor] - the vendor to show
 * @returns {JSX.Element} VendorHeader component
 */
export default function VendorHeader({ vendor }) {
  const validityTypes = new Set(vendor?.vouchers?.map(voucher => voucher.redemption.validity));
  const validityText = validityTypes.size === 1 ? validityTypes.values().next().value.toLowerCase() : `either ${[...validityTypes].map(validity => validity.toLowerCase()).join(' or ')}`;
  const redemptionTypes = new Set(vendor?.vouchers?.map(voucher => voucher.redemption.type));
  const redemptionText = redemptionTypes.size === 1 ? redemptionTypes.values().next().value : `either ${[...redemptionTypes].map(redemption => redemption.toLowerCase()).join(' or ')}`;
  const isEmpty = !vendor;
  return (
    <header className='vendor-header v-container-h'>
      <VendorCard
        vendor={ vendor }
        hideInfo={ true }
      />
      <h3 className={ isEmpty ? 'empty skeleton-loader' : undefined }>{vendor?.name}</h3>
      {
        validityTypes.size > 0 && redemptionTypes.size > 0
          && <p>All vouchers {validityText}, {redemptionText}</p>
      }
      {isEmpty && <><p className='empty skeleton-loader' /><p className='empty skeleton-loader' /></>}
    </header>
  );
}

VendorHeader.propTypes = { vendor: PropTypeShapes.vendor };
