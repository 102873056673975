import { createContext, Context, Dispatch, SetStateAction } from 'react';

/**
 * @type {Context<JSX.Element>}
 */
export const PopupContext = createContext(undefined);

/**
 * @type {Context<Dispatch<SetStateAction<Element>>>}
 */
export const UpdatePopupContext = createContext(value => value);

/**
 * Context for Analytics
 *
 * @type {Context<object>}
 */
export const AnalyticsPopupContext = createContext(null);

/**
 * Context for Analytics
 *
 * @type {Context<Dispatch<SetStateAction<object>>>}
 */
export const UpdateAnalyticsPopupContext = createContext(null);
