import { createContext, Context, Dispatch, SetStateAction } from 'react';
import { Gift } from '../Constants/ObjectDefinitions.js';

/**
 * @type {Context<Array<Gift>>}
 */
export const HistoryContext = createContext(undefined);

/**
 * @type {Context<Dispatch<SetStateAction<Array<Gift>>>>}
 */
export const UpdateHistoryContext = createContext(def => def);
