import React, { useContext } from 'react';
import './PaymentSummaryBreakdown.css';
import KeyValueList from '../KeyValueList/KeyValueList.jsx';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import { PaymentAmountContext } from '../../services/context/PaymentAmountContext.js';

/**
 * @returns {JSX.Element} PaymentSummaryBreakdown Component
 */
export default function PaymentSummaryBreakdown() {
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const payment = MoneyFormatter.get(Number(useContext(PaymentAmountContext)));
  return (
    <section className='payment-summary-breakdown v-container'>
      <h4>Cost details</h4>
      <div className='wrapper'>
        <div className='total'>
          <KeyValueList object={ { 'Payment amount': MoneyFormatter.format(payment) } } />
        </div>
      </div>
    </section>
  );
}
