import React, { useContext } from 'react';
import './RocketContinue.css';
import { RocketContext } from '../../services/context/RocketContext.js';
import PropTypes from 'prop-types';

/**
 * @param {object} props - the props of the component
 * @param {boolean} props.isFailure - whether successful or not
 * @returns {JSX.Element} SuccessContinue Component
 */
export default function RocketContinue({ isFailure }) {
  const { onContinue } = useContext(RocketContext);
  return (
    <section className='success-continue v-container'>
      <button
        className='tertiary-button'
        onClick={ onContinue }
      >{isFailure ? 'OK' : 'Continue shopping'}</button>
    </section>
  );
}

RocketContinue.propTypes = { isFailure: PropTypes.bool };
