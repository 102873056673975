import React from 'react';
import './DatePicker.css';
import PropTypes from 'prop-types';

/**
 *
 * @param {object} props - The props for the component
 * @param {Array} props.availableDates - array of available Dates
 * @param {Function} props.setSelected - function to setSelectedDate
 * @param {Date} props.selected - the current selected date
 * @returns {JSX.Element} - DatePicker component
 */
export default function DatePicker({ availableDates, setSelected, selected }) {
  const lastAvailable = availableDates.reduce((accumulator, date) => (accumulator.getTime() > date.getTime() ? accumulator : date), new Date());

  const months = [];
  const isSameYear = (date, otherDate) => date.getFullYear() === otherDate.getFullYear();
  const isSameMonth = (date, otherDate) => date.getMonth() === otherDate.getMonth();
  const isSameDate = (date, otherDate) => date.getDate() === otherDate.getDate();
  const isSameDay = (date, otherDate) => isSameYear(date, otherDate) && isSameMonth(date, otherDate) && isSameDate(date, otherDate);
  const beforeThisYear = (date, beforeThis) => date.getFullYear() < beforeThis.getFullYear();
  const beforeThisMonth = (date, beforeThis) => date.getFullYear() === beforeThis.getFullYear() && date.getMonth() <= beforeThis.getMonth();

  const today = new Date(Date.now());
  const monthOfToday = today.getMonth();
  const days = [];
  const date = new Date(today);
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  while (date.getMonth() === monthOfToday) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  months.push(days);
  while (beforeThisYear(date, lastAvailable) || beforeThisMonth(date, lastAvailable)) {
    const month = date.getMonth();
    const daysInMonth = [];
    while (date.getMonth() === month) {
      daysInMonth.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    months.push(daysInMonth);
  }

  const monthElements = months.map(month => (
    <section
      className='month'
      key={ month.at(0).toString() }
    >
      <header>{
        month.at(0).toLocaleString('default', {
          month: 'long',
          year: 'numeric',
        })
      }</header>
      <ul>
        {
          [
            ...Array.from({ length: month.at(0).getDay() }, (value, index) => (
              <div
                key={ index }
                className='v-container-vh'
              />
            )),
            ...month.map(day => (
              <div
                className='v-container-vh'
                key={ day.toString() }
              >
                <button
                  className={ `${selected && isSameDay(day, selected) ? 'selected ' : ''}${isSameDay(day, today) ? 'today' : ''}` }
                  disabled={ !availableDates.some(available => isSameDay(day, available)) }
                  onClick={ () => setSelected(day) }
                >
                  {day.getDate()}
                </button>
              </div>
            )),
          ]
        }
      </ul>
    </section>
  ));

  return (
    <div className='date-picker v-container'>
      {monthElements}
    </div>
  );
}

DatePicker.propTypes = {
  availableDates: PropTypes.arrayOf(PropTypes.object),
  setSelected: PropTypes.func,
  selected: PropTypes.object,
};
