import React from 'react';
import MaintenanceMan from '../../components/MaintenanceMan/MaintenanceMan.jsx';
import MaintenanceMessage from '../../components/MaintenanceMessage/MaintenanceMessage.jsx';
import { withProfiler } from '@sentry/react';

/**
 * @returns {JSX.Element} MaintenancePage component
 */
function MaintenancePage() {
  return (
    <>
      <MaintenanceMan />
      <MaintenanceMessage />
    </>
  );
}

export default withProfiler(MaintenancePage);
