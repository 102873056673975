export const SentryConfig = {
  admin: {
    changeCard: {
      transaction: {
        name: 'Change Card Transaction',
        op: 'change_card_transaction',
        description: 'A transaction to trace the change card process.',
      },
      spans: {
        processChangeCard: {
          op: 'change_card',
          description: 'Beginning change card transaction after successful authentication.',
        },
        changeCardCall: {
          op: 'change_card_call',
          description: 'Performs the change card call.',
        },
      },
    },
    history: {
      transaction: {
        name: 'Get History Call',
        op: 'get_history_transaction',
        description: 'Transaction to trace the history call.',
      },
      spans: {
        getHistory: {
          op: 'get_history_call',
          description: 'Gets the user\'s history.',
        },
      },
    },
    initialCatalogue: {
      transaction: {
        name: 'Initial Catalogue/Categories Call',
        op: 'initial_catalogue_call_transaction',
        description: 'Transaction for the initial catalogue/categories call.',
      },
      spans: {
        getCatalogue: (tries, maxTries) => ({
          op: 'catalogue_call_get_categories',
          description: `Initial operation to get the catalogue/categories: attempt ${tries + 1} of ${maxTries}.`,
        }),
        processCatalogue: {
          op: 'process_catalogue_response',
          description: 'Frontend processing of the catalogue response, sorting and merging categories.',
        },
      },
    },
  },
  auth: {
    bearerToken: {
      transaction: {
        name: 'Bearer Token State Provider',
        op: 'bearer_token_state_provider',
        description: 'Transaction for the bearer token context provider\'s processes.',
      },
      spans: {
        getBearerToken: {
          op: 'get_bearerToken',
          description: 'Gets the bearer token for the user context.',
        },
      },
    },
    authHook: {
      transaction: {
        name: 'Auth Hook Transaction',
        op: 'use_auth_hook_service',
        description: 'A transaction to trace the useAuthService hook',
      },
      spans: {
        getAuthCall: config => ({
          op: 'get_auth_call',
          description: `Tries auth call from ${config.external.vodapayGateway.v2.applyAuthCodeUrl}`,
        }),
        miniAppAuthCall: {
          op: 'mini_app_auth_call',
          description: 'Authentication call using MiniApp auth handler',
        },
      },
    },
    initialAuth: {
      transaction: {
        name: 'Initial Authentication',
        op: 'authorization_and_gets_token',
        description: 'Transaction for initial authentication then gets bearer token from auth code.',
      },
      spans: {
        initAuth: {
          op: 'initial_authentication_call',
          description: 'Performs initial authentication.',
        },
        getBearerToken: {
          op: 'get_bearer_from_auth_code',
          description: 'Gets the bearer token from the auth code.',
        },
      },
    },
  },
  payments: {
    advance: {
      transaction: {
        name: 'Processing Advance',
        op: 'advance_transaction',
        description: 'A transaction to trace the full advance process.',
      },
      spans: {
        getCartUrl: {
          op: 'get_cart_url',
          description: 'Gets the url of the user\'s cart.',
        },
        updateCartUrl: {
          op: 'update_cart_url',
          description: 'Updates the url of the user\'s cart.',
        },
        getCheckoutUrl: {
          op: 'get_checkout_url',
          description: 'Checks out the user\'s cart.',
        },
        reserve: {
          op: 'reserve_advance',
          description: 'Reserves the the user\'s advance.',
        },
        auth: {
          op: 'authorization',
          description: 'Authorizes user.',
        },
        createPaymentUrl: {
          op: 'create_payment_url',
          description: 'Creates the payment URL.',
        },
        setPaymentHandler: {
          op: 'set_payment_handler',
          description: 'Sets the payment handler.',
        },
      },
    },
    buyNow: {
      success: {
        transaction: {
          name: 'Processing Buy Now',
          op: 'buynow_transaction',
          description: 'A transaction to trace the full buy now process.',
        },
        spans: {
          getCartUrl: {
            op: 'get_cart_url',
            description: 'Gets the url of the user\'s cart.',
          },
          updateCartUrl: {
            op: 'update_cart_url',
            description: 'Updates the url of the user\'s cart.',
          },
          getCheckoutUrl: {
            op: 'get_checkout_url',
            description: 'Checks out the user\'s cart.',
          },
          reserve: {
            op: 'reserve_buynow',
            description: 'Reserves the the user\'s buy now purchase order.',
          },
          auth: {
            op: 'authorization',
            description: 'Authorizes user.',
          },
          createPaymentUrl: {
            op: 'create_payment_url',
            description: 'Creates the payment URL.',
          },
          setPaymentHandler: {
            op: 'set_payment_handler',
            description: 'Sets the payment handler.',
          },
        },
      },
      networkError: {
        transaction: {
          name: 'Buy Now Network Error Handler',
          op: 'buynow_network_error_handling',
          description: 'Traces handling of network errors on buy now transactions.',
        },
        spans: {
          retry: {
            op: 'retrying_buynow',
            description: 'Cart not found - retrying buy now.',
          },
          reAuth: {
            op: 're-authorization',
            description: 'Performing re-authorization to retry.',
          },
          update: {
            op: 'updating_cart',
            description: 'Updating cart - removed failed vouchers from cart.',
          },
          getBearerToken: {
            op: 'get_bearer_token',
            description: 'Getting user\'s bearer token.',
          },
          updateBearerAndRetry: {
            op: 'update_bearer_and_retry_buynow',
            description: 'Updating the bearer token and retrying buy now transaction.',
          },
        },
      },
    },
    earlyRepayment: {
      transaction: {
        name: 'Early Repayment Transaction',
        op: 'early_repayment_transaction',
        description: 'A transaction to trace the early repayment process.',
      },
      spans: {
        tryEarlyRepayment: {
          op: 'early_repayment_process',
          description: 'Performs the early repayment process, including authorization.',
        },
      },
    },
    gatewayErrors: {
      transaction: {
        name: 'Payment Error Handler',
        op: 'handle_payment_error',
        description: 'A transaction to trace payment errors.',
      },
    },
    paymentResponse: {
      advance: {
        transaction: {
          name: 'Payment Response Handler: Advance',
          op: 'payment_response_transaction',
          description: 'Traces the payment response useEffect on advances.',
        },
        spans: {
          retry: {
            op: 'retrying_advance',
            description: 'Cart not found - retrying advance.',
          },
          reAuth: {
            op: 're-authorization',
            description: 'Performing re-authorization to retry.',
          },
          getBearerToken: {
            op: 'get_bearer_token',
            description: 'Getting user\'s bearer token.',
          },
          updateBearerAndRetry: {
            op: 'update_bearer_and_retry_advance',
            description: 'Updating the bearer token and retrying advance_transaction.',
          },
        },
      },
      buyNow: {
        transaction: {
          name: 'Payment Response Handler: Buy Now',
          op: 'payment_response_transaction',
          description: 'Traces the payment response useEffect on buy now.',
        },
        spans: {
          success: {
            op: 'handle_successful_buy_now',
            description: 'Handler for successful buy now payment response.',
          },
          paymentError: {
            op: 'handle_payment_error_payment_response',
            description: 'Handler for error response from buy now payment.',
          },
          networkError: {
            op: 'handle_network_error_payment_response',
            description: 'Handler for error response from buy now payment.',
          },
        },
      },
    },
  },
};
