import React, { useContext, useEffect, useState } from 'react';
import './ShareCoupon.css';
import Coupon from '../../components/Coupon/Coupon.jsx';
import ExpandedCouponBottom from '../../components/CouponBottoms/Expanded/ExpandedCouponBottom.jsx';
import { CurrentCouponContext, UpdateCurrentCouponContext } from '../../services/context/CurrentCouponContext.js';
import ShareIcon from '../../assets/ShareIcon.jsx';
import { toPng } from 'html-to-image';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { getVoucherVendor } from '../../services/ReverseHierarchySearch.js';
import { CategoriesContext } from '../../services/context/CategoriesContext.js';
import MiniAppMessageTypes from '../../services/Constants/MiniAppMessageTypes.js';
import { UpdateMiniAppShareContext } from '../../services/context/MiniAppContext.js';
import Redirect from '../../components/Redirect/Redirect.jsx';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { withProfiler } from '@sentry/react';
import DualButtons from '../../components/DualButtons/DualButtons.jsx';
import { useNavigate } from 'react-router-dom';

/**
 *
 * @param {string} dataUrl - dataUrl to make a file from
 * @param {string} filename - the name of the file to create
 * @returns {File} the created file
 */
function dataURLtoFile(dataUrl, filename) {
  const arr = dataUrl.split(',');
  const [mime] = arr[0].match(/:.*?;/);
  const type = mime.slice(1, -1);
  const bStr = atob(arr[1]);
  let n = bStr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bStr.codePointAt(n);
  }

  return new File([u8arr], filename, { type });
}

/**
 * @returns {JSX.Element} ShareCoupon Component
 */
function ShareCoupon() {
  const [message, setMessage] = useState('');
  const [isSharing, setIsSharing] = useState(false);
  const maxLen = 120;
  const gift = useContext(CurrentCouponContext);
  const categories = useContext(CategoriesContext);
  const navigateTo = useNavigate();
  const setGift = useContext(UpdateCurrentCouponContext);
  const setShareHandler = useContext(UpdateMiniAppShareContext);
  useEffect(() => {
    if (gift) {
      window.utag?.view({
        ...defaultAnalyticsVariables,
        page_name: `${pagePrefix}: share voucher`,
        event_name: [events.pageView],
      });
    }
  }, []);
  if (!gift) return <Redirect to={ ROUTES.MY_VOUCHERS } />;
  return (
    <div className='share-coupon v-container'>
      <section
        className='v-container'
      >
        <h3>Share voucher</h3>
        <Coupon
          id='share-coupon'
          gift={ gift }
          bottom={ <ExpandedCouponBottom gift={ gift } /> }
        />
        <div className='textarea-wrapper v-container'>
          <textarea
            disabled={ isSharing }
            rows={ 3 }
            wrap='soft'
            maxLength={ maxLen }
            value={ message }
            onChange={
              event => {
                setMessage(event.target.value.replaceAll(/\r\n|\n|\r/gm, '')); // remove any linebreaks
              }
            }
            placeholder='Write a message'
          />
          <p className='counter'>{message.length}/{maxLen}</p>
        </div>
      </section>
      <DualButtons
        primaryOnClick={
          () => {
            setIsSharing(true);
            const coupon = document.querySelector('#share-coupon');

            window.utag?.link({
              ...defaultAnalyticsVariables,
              page_name: `${pagePrefix}: share voucher`,
              event_name: [events.interaction],
              link_id: `${pagePrefix}: share voucher: ${getVoucherVendor(categories, gift.voucher)?.name}`,
            });

            toPng(coupon).then(dataUrl => {
              const file = dataURLtoFile(dataUrl, `${gift.name}.png`);
              if (navigator.canShare && navigator.share && navigator.canShare({ files: [file] })) { // native share
                const data = {
                  title: gift.name,
                  text: message,
                  files: [file],
                };
                navigator.share(data).finally(() => {
                  setGift(null);
                  setIsSharing(false);
                });
              } else if (window.my) { // mini app share
                setShareHandler(() => () => {
                  setGift(null);
                  setIsSharing(false);
                });
                window.my.postMessage({
                  messageType: MiniAppMessageTypes.share,
                  data: {
                    title: gift.name,
                    text: message,
                    image: dataUrl,
                  },
                }, '*');
              } else { // manual download
                const downloader = document.createElement('a');
                downloader.setAttribute('href', dataUrl);
                downloader.setAttribute('download', `${gift.name}.png`);
                downloader.click();
                downloader.remove();
                setGift(null);
                setIsSharing(false);
              }
            });
          }
        }
        primaryText={ <div className='container-vh'>Send voucher<ShareIcon /></div> }
        secondaryOnClick={ () => navigateTo(-1) }
        secondaryText={ <>Back</> }
      />
    </div>
  );
}

export default withProfiler(ShareCoupon);
