import React from 'react';
import CartIcon from '../assets/CartIcon.jsx';
import { captureException, captureMessage, startTransaction } from '@sentry/react';
import TradePayCodes from './Constants/TradePayCodes.js';
import { SentryConfig } from './Constants/Sentry.js';
import RecurringPaymentCodes from './Constants/RecurringPaymentCodes.js';

const defaultSnackResponse = {
  icon: <CartIcon />,
  body: 'Something went wrong with payment',
};

export const handlePaymentError = (resultCode, setSnackBar) => {
  const paymentErrorTransaction = startTransaction(SentryConfig.payments.gatewayErrors.transaction);
  switch (resultCode) {
    case TradePayCodes.CANCELLED: {
      captureMessage(`Payment Gateway response - ${TradePayCodes.CANCELLED}: User cancelled payment request`, 'info');
      break;
    }

    case TradePayCodes.FAILED: {
      setSnackBar({
        icon: <CartIcon />,
        body: 'Payment failed',
      });

      break;
    }

    default: {
      captureException(new Error(`Unexpected Payment Gateway result code: ${resultCode}`));
      setSnackBar(defaultSnackResponse);
      break;
    }
  }

  paymentErrorTransaction.finish();
};

export const handleAdvanceError = (responseCode, setSnackBar) => {
  switch (responseCode) {
    case RecurringPaymentCodes.CANCELLED: {
      captureMessage(`Recurring Payment response - ${RecurringPaymentCodes.CANCELLED}: User cancelled payment request`, 'info');
      break;
    }

    case RecurringPaymentCodes.SUS_FRAUD: {
      captureMessage(`Recurring Payment response - ${RecurringPaymentCodes.SUS_FRAUD}: Suspected Fraud`, 'warning');
      setSnackBar(defaultSnackResponse);
      break;
    }

    default: {
      captureException(new Error(`Unexpected Recurring Payment response code: ${responseCode}`));
      setSnackBar(defaultSnackResponse);
      break;
    }
  }
};
