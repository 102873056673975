import React, { useContext, useEffect, useState } from 'react';
import RocketHeader from '../../components/RocketHeader/RocketHeader.jsx';
import RocketBody from '../../components/RocketBody/RocketBody.jsx';
import RocketAnimation from '../../components/RocketAnimation/RocketAnimation.jsx';
import { RocketContext } from '../../services/context/RocketContext.js';
import PropTypes from 'prop-types';
import { withProfiler } from '@sentry/react';

/**
 * @param {object} props - the props of the component
 * @param {boolean} props.isFailure - whether success or failure
 * @returns {JSX.Element} PurchaseSuccess Page
 */
function RocketPage({ isFailure }) {
  const [isRocketTime, setIsRocketTime] = useState(true);
  const { analytics } = useContext(RocketContext);

  useEffect(() => {
    if (analytics) window.utag?.view(analytics);
  }, []);

  setTimeout(() => setIsRocketTime(false), 1500);
  return (
    <>
      {
        isRocketTime
        && <RocketAnimation isFailure={ isFailure } />
      }
      {
        !isRocketTime
        && <>
          <RocketHeader isFailure={ isFailure } />
          <RocketBody isFailure={ isFailure } />
        </>
      }
    </>
  );
}

RocketPage.propTypes = { isFailure: PropTypes.bool };

export default withProfiler(RocketPage);
