import React from 'react';
import NavItem from '../NavItem.jsx';
import HomeIcon from '../../../assets/HomeIcon.jsx';
import ROUTES from '../../../services/Constants/GlobalRoutes.jsx';

/**
 * @returns {JSX.Element} NavHome Component
 */
export default function NavHome() {
  return (
    <NavItem
      icon={ <HomeIcon /> }
      route={ ROUTES.HOME }
      name='Home'
    />
  );
}
