import React, { useContext, useEffect, useRef, useState } from 'react';
import { defaultAnalyticsVariables, events, pagePrefix, traceId } from '../../services/Constants/Analytics.js';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { AdvanceContext, UpdateAdvanceContext } from '../../services/context/AdvanceContext.js';
import PaymentSummaryBreakdown from '../../components/PaymentSummaryBreakdown/PaymentSummaryBreakdown.jsx';
import { PaymentAmountContext } from '../../services/context/PaymentAmountContext.js';
import PaymentSummaryHeader from '../../components/PaymentSummaryHeader/PaymentSummaryHeader.jsx';
import { CurrentCouponLoanDetailsContext } from '../../services/context/CurrentCouponContext.js';
import AdvanceDisclaimer from '../../components/AdvanceDisclaimer/AdvanceDisclaimer.jsx';
import DualButtons from '../../components/DualButtons/DualButtons.jsx';
import { withSentryTransaction } from '../../services/Sentry.js';
import { SentryConfig } from '../../services/Constants/Sentry.js';
import { processAdvanceIntoRequest } from '../../services/ResponseProcessor.js';
import { getTerm } from '../../services/AdvanceTermCalculator.js';
import MiniAppMessageTypes from '../../services/Constants/MiniAppMessageTypes.js';
import HttpCodes from '../../services/Constants/HttpCodes.js';
import { addBreadcrumb, captureMessage, startTransaction } from '@sentry/react';
import CartIcon from '../../assets/CartIcon.jsx';
import RocketCouponDisplay from '../../components/RocketCouponDisplay/RocketCouponDisplay.jsx';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { getVendorCategory, getVoucherVendor } from '../../services/ReverseHierarchySearch.js';
import Coupon from '../../components/Coupon/Coupon.jsx';
import StubCouponBottom from '../../components/CouponBottoms/Stub/StubCouponBottom.jsx';
import { handleAdvanceError } from '../../services/ErrorHandler.jsx';
import { BearerTokenContext, UpdateBearerTokenContext } from '../../services/context/BearerTokenContext.js';
import { UpdateSnackBarContext } from '../../services/context/SnackBarContext.js';
import { UpdateMiniAppAdvanceContext } from '../../services/context/MiniAppContext.js';
import { UpdateRocketContext } from '../../services/context/RocketContext.js';
import { CategoriesContext } from '../../services/context/CategoriesContext.js';
import useApis from '../../services/hooks/useApis.js';
import { EligibilityContext, UpdateEligibilityContext } from '../../services/context/EligibilityContext.js';
import { ConfigContext } from '../../services/context/ConfigContext.js';
import { UpdateHistoryContext } from '../../services/context/HistoryContext.js';
import { useNavigate } from 'react-router-dom';
import useAuthService from '../../services/hooks/useAuthService.js';
import Loading from '../../components/Loading/Loading.jsx';
import { getReservationFailureReason, NetworkError, VoucherAddError, VoucherReservationError } from '../../services/Constants/Errors.js';
import { CreateAdvanceResponse } from '../../services/Constants/ApiResponses.js';
import { UserDetailsContext } from '../../services/context/UserDetailsContext.js';
import { UpdatePopupContext } from '../../services/context/PopupContext.js';
import InvoiceEmailCapture from '../../components/EmailCapture/InvoiceEmailCapture.jsx';

/**
 * @returns {JSX.Element} CartPage component
 */
export default function AdvanceSummary() {
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const [{ voucher, date }, setAdvance] = [
    useContext(AdvanceContext),
    useContext(UpdateAdvanceContext),
  ];
  const { doAuth } = useAuthService();
  const bearerTokenContext = useContext(BearerTokenContext);
  const updateBearerToken = useContext(UpdateBearerTokenContext);
  const setPopup = useContext(UpdatePopupContext);
  const setSnackBar = useContext(UpdateSnackBarContext);
  const [loading, setLoading] = useState(/** @type {string} */null);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const setAdvanceHandler = useContext(UpdateMiniAppAdvanceContext);
  const setRocket = useContext(UpdateRocketContext);
  const categories = useContext(CategoriesContext);
  const { getBearerToken, getCart, addToCart, checkoutCart, reserveVoucher, reserveOrder, createAdvancePayment } = useApis();
  const { eligibilityRequestId } = useContext(EligibilityContext);
  const config = useContext(ConfigContext);
  const setEligibility = useContext(UpdateEligibilityContext);
  const setHistory = useContext(UpdateHistoryContext);
  const navigateTo = useNavigate();
  const analyticsName = `${pagePrefix}: advance summary`;
  const totalCost = MoneyFormatter.getValue(voucher.cost);
  const userDetails = useContext(UserDetailsContext);

  const cartIdRef = useRef('');
  const checkoutRef = useRef(/** @type {{cartId: string, orderId: number}} */null);
  const authCodeRef = useRef('');
  const bearerTokenRef = useRef('');
  const advancePaymentRef = useRef(/** @type {CreateAdvanceResponse} */null);
  const reservationsRef = useRef(/** @type {Array<Promise<Response>>} */null);

  useEffect(() => {
    window.utag?.view({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [
        events.pageView,
        events.cartView,
      ],
    });
  }, []);

  /**
   * @param {NetworkError} error - failed network response
   * @param {function(string)} retry - retry function for recoverable network errors
   * @returns {Promise<void>}
   */
  const handleNetworkError = (error, retry) => {
    switch (error.response.status) {
      case HttpCodes.UNAUTHORIZED:
      // falls through

      case HttpCodes.FORBIDDEN: {
        return withSentryTransaction({
          sentryTransaction: SentryConfig.payments.paymentResponse.transaction,
          steps: [
            {
              transaction: SentryConfig.payments.paymentResponse.advance.spans.reAuth,
              worker: async () => {
                setLoading('Retrying...');
                authCodeRef.current = await doAuth();
              },
            },
            {
              transaction: SentryConfig.payments.paymentResponse.advance.spans.getBearerToken,
              worker: async () => {
                const bearerResponse = await getBearerToken(authCodeRef.current);
                bearerTokenRef.current = bearerResponse.data.tokens.accessToken;
              },
            },
            {
              transaction: SentryConfig.payments.paymentResponse.advance.spans.updateBearerAndRetry,
              worker: async () => {
                updateBearerToken(bearerTokenRef.current);
                return await retry(bearerTokenRef.current);
              },
            },
          ],
        });
      }

      default: {
        window.utag?.link({
          ...defaultAnalyticsVariables,
          page_name: analyticsName,
          event_name: [events.error],
          link_id: `${pagePrefix}: error`,
          event_error_name: `unexpected advance network error: ${error.response.status}`,
          event_error_code: error.response.headers?.get(traceId),
          event_error_type: 'system error',
        });
        captureMessage(`Unexpected network code in advance: ${error.response.status}`, 'warning');
        setSnackBar({
          icon: <CartIcon />,
          body: 'Whoops, something went wrong',
        });
        setLoading(null);

        break;
      }
    }
  };

  /**
   * @param {VoucherAddError} failure - voucher add failure
   */
  const handleAddFailure = async failure => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.error],
      link_id: `${pagePrefix}: error`,
      event_error_name: 'magento advance add failure',
      event_error_type: 'user error',
    });
    const { addError } = failure;
    const failureBody = await addError.response.json();
    const { cartAddFailureReasons } = failureBody.data;
    const failures = cartAddFailureReasons.map(({ reason }) => ({
      ...voucher,
      reason,
    }));
    setRocket({
      body: (
        <RocketCouponDisplay
          isFailure
          cart={ failures }
        />
      ),
      header: (
        <>
          <h3>Failed to add item!</h3>
          <p>The following voucher{failures.length === 1 ? '' : 's'} failed to be added.</p>
        </>),
      onContinue: () => navigateTo(-1),
    });
    navigateTo(ROUTES.FAILURE);
  };

  /**
   * @param {VoucherReservationError} failure - reservation failure
   */
  const handleReservationFailure = failure => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.error],
      link_id: `${pagePrefix}: error`,
      event_error_name: 'cvm advance reservation failure',
      event_error_type: 'user error',
    });
    const { responses } = failure;
    /**
     * @type {Array<NetworkError>}
     */
    const errors = responses.map(({ status, reason }) => (status === 'rejected' ? reason : null));
    Promise.all(errors.map(async reason => ({
      ...voucher,
      reason: await getReservationFailureReason(reason),
    }))).then(failures => {
      for (const { id, reason } of failures) {
        addBreadcrumb({
          category: 'reservation failure',
          message: `${id}: ${reason ?? 'unknown reason'}`,
          level: reason ? 'info' : 'warning',
        });
      }

      captureMessage('Voucher Advance Reservation Failed', 'info');
      setRocket({
        body: <RocketCouponDisplay
          isFailure
          cart={ failures }
        />,
        header: <>
          <h3>Reservation failure!</h3>
          <p>The following voucher{failures.length === 1 ? '' : 's'} failed reservation.</p>
        </>,
        onContinue: () => navigateTo(-1),
      });

      navigateTo(ROUTES.FAILURE, { replace: true });
    });
  };

  const processAdvance = async (bearerToken, updatedUserDetails) => {
    await withSentryTransaction({
      sentryTransaction: SentryConfig.payments.advance.transaction,
      exceptionCallback: error => {
        if (error instanceof NetworkError) handleNetworkError(error, processAdvance);
        else if (error instanceof VoucherReservationError) handleReservationFailure(error);
        else if (error instanceof VoucherAddError) handleAddFailure(error);
      },
      steps: [
        {
          transaction: SentryConfig.payments.advance.spans.getCartUrl,
          worker: async () => {
            setLoading('Adding everything to your cart...');
            const { cartId } = await getCart(bearerToken);
            cartIdRef.current = cartId;
          },
        },
        {
          transaction: SentryConfig.payments.advance.spans.updateCartUrl,
          worker: async () => {
            await addToCart(bearerToken, cartIdRef.current, processAdvanceIntoRequest(voucher, date))
              .catch(/** @param {NetworkError} error - add to cart network error */ error => {
                if (!(error instanceof NetworkError) || error.response.status !== HttpCodes.CONFLICT) throw error;
                throw new VoucherAddError(error);
              });
          },
        },
        {
          transaction: SentryConfig.payments.advance.spans.getCheckoutUrl,
          worker: async () => {
            setLoading('Checking out...');
            checkoutRef.current = await checkoutCart(bearerToken, cartIdRef.current, updatedUserDetails);
          },
        },
        {
          transaction: SentryConfig.payments.advance.spans.reserve,
          worker: async () => {
            setLoading('Reserving your order...');
            reservationsRef.current = [
              reserveVoucher(bearerToken, {
                voucher,
                sequenceNo: 1,
                orderId: checkoutRef.current.orderId,
              }, true),
            ];
          },
        },
        {
          transaction: SentryConfig.payments.advance.spans.reserve,
          worker: async () => {
            const reservations = await Promise.allSettled(reservationsRef.current);
            if (reservations.some(({ status }) => status === 'rejected')) throw new VoucherReservationError(reservations);
            await reserveOrder(bearerToken, checkoutRef.current.orderId, reservations.map(promise => promise.value));
          },
        },
        {
          transaction: SentryConfig.payments.advance.spans.auth,
          worker: async () => {
            setLoading('Authorizing payment...');
            authCodeRef.current = await doAuth();
          },
        },
        {
          transaction: SentryConfig.payments.advance.spans.createPaymentUrl,
          worker: async () => {
            setLoading('Getting ready for advance...');
            advancePaymentRef.current = await createAdvancePayment(bearerToken, authCodeRef.current, {
              eligibilityRequestId,
              orderId: checkoutRef.current.orderId,
              paymentDate: date,
              offerId: getTerm(voucher, date).offerId,
            });
          },
        },
        {
          transaction: SentryConfig.payments.advance.spans.setPaymentHandler,
          worker: async () => {
            if (!window.my) {
              // eslint-disable-next-line no-alert
              const mocked = confirm('mock success?');
              setPaymentResponse({ ok: mocked });
            } else {
              setAdvanceHandler(() => message => setPaymentResponse(message.data));
              window.my.postMessage({
                messageType: MiniAppMessageTypes.advance,
                data: {
                  paymentUrl: advancePaymentRef.current.result.initiationUrl,
                  adjustData: {
                    token: config.frontend.adjustTokens.paymentOptions.advance,
                    revenue: {
                      amount: totalCost.amount,
                      currency: totalCost.unit,
                    },
                  },
                },
              }, '*');
            }
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (paymentResponse) {
      const paymentResponseTransaction = startTransaction(SentryConfig.payments.paymentResponse.transaction);
      setLoading(null);
      if (paymentResponse.ok) {
        setAdvance(null);
        setHistory(null);
        setEligibility(null);
        setRocket({
          analytics: {
            ...defaultAnalyticsVariables,
            page_name: `${pagePrefix}: advance success`,
            event_name: [
              events.pageView,
              events.advanceSuccess,
              events.transactionSuccess,
            ],
            product_category: getVendorCategory(categories, getVoucherVendor(categories, voucher))?.name,
            product_name: voucher.name,
            product_price: totalCost.amount,
            product_id: voucher.id,
            product_quantity: 1,
            transaction_payment_type: 'advance',
          },
          body: (<Coupon
            gift={ { voucher } }
            bottom={
              <StubCouponBottom gift={
                {
                  voucher,
                  value: MoneyFormatter.getValue(voucher.options.advance.value),
                }
              }
              />
            }
          />),
          header: (
            <>
              <h3>Purchase successful!</h3>
              <p>Your voucher will be available in 2 minutes, you can view it under “My Vouchers”.</p>
            </>),
          onContinue: () => navigateTo(ROUTES.HOME, { replace: true }),
        });
        captureMessage('Successful payment response received', 'info');

        navigateTo(ROUTES.SUCCESS, { replace: true });
      } else {
        const { responseCode } = paymentResponse;
        handleAdvanceError(responseCode, setSnackBar);
      }

      paymentResponseTransaction.finish();
    }
  }, [paymentResponse]);

  const needEmail = voucher.stock.type === 'simple';

  return (
    <AnalyticsPageContext.Provider value={ analyticsName }>
      <CurrentCouponLoanDetailsContext.Provider value={
        {
          principal: MoneyFormatter.getValue(voucher.cost).amount,
          fees: 0,
          outstanding: MoneyFormatter.getValue(voucher.cost).amount,
        }
      }
      >
        <PaymentSummaryHeader />
      </CurrentCouponLoanDetailsContext.Provider>
      <PaymentAmountContext.Provider value={ MoneyFormatter.getValue(voucher.cost).amount.toString() }>
        <PaymentSummaryBreakdown />
      </PaymentAmountContext.Provider>
      <AdvanceDisclaimer />
      <DualButtons
        primaryOnClick={
          needEmail
            ? () => setPopup(<InvoiceEmailCapture onCapture={ updatedUserDetails => processAdvance(bearerTokenContext, updatedUserDetails) } />)
            : () => processAdvance(bearerTokenContext, userDetails)
        }
        secondaryOnClick={ () => navigateTo(-1) }
        primaryText={ <>Get advance {MoneyFormatter.format(MoneyFormatter.getValue(voucher.cost))}</> }
        primaryPayment
        secondaryText={ <>Cancel</> }
      />

      { loading ? <Loading headerText={ loading } /> : null}
    </AnalyticsPageContext.Provider>
  );
}
