import React, { Dispatch, SetStateAction } from 'react';
import './MyVouchersHeader.css';
import PropTypes from 'prop-types';
/**
 *
 * @param {object} props - the props of the component
 * @param {Array<{ display: string}>}props.filters - the filters to filter by
 * @param {number} props.currentFilter - the index of the current filter
 * @param {Dispatch<SetStateAction<number>>} props.setCurrentFilter - a function to update the current filter
 * @returns {JSX.Element} MyVouchersHeader Component
 */
export default function MyVouchersHeader({ filters, currentFilter, setCurrentFilter }) {
  return (
    <header className='my-vouchers-header'>
      <h3>
        My purchased vouchers
      </h3>

      <ul className='container'>
        {
          filters.map((filter, index) => (
            <button
              key={ index }
              onClick={ () => setCurrentFilter(index) }
              className={ index === currentFilter ? 'selected' : '' }
            >
              {filter.display}
            </button>
          ))
        }
      </ul>
    </header>
  );
}

MyVouchersHeader.propTypes = {
  currentFilter: PropTypes.number,
  filters: PropTypes.arrayOf(PropTypes.shape({ display: PropTypes.string })),
  setCurrentFilter: PropTypes.func,
};
