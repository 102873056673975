import './CategoryCard.css';
import React, { useContext } from 'react';
import VendorCard from '../../Vendor/Card/VendorCard.jsx';
import PropTypes from 'prop-types';
import PropTypeShapes from '../../../services/Constants/PropTypeShapes.jsx';
import { useNavigate } from 'react-router-dom';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../../services/context/AnalyticsPageContext.js';
import ROUTES from '../../../services/Constants/GlobalRoutes.jsx';
import { Category } from '../../../services/Constants/ObjectDefinitions.js';
import { ConfigContext } from '../../../services/context/ConfigContext.js';

/**
 *
 * @param {object} props - the props of the component
 * @param {Category} props.category - the category to display
 * @param {string} props.description - the description for the category
 * @returns {JSX.Element} CategoryCard Component
 */
export default function CategoryCard({ category, description }) {
  const config = useContext(ConfigContext);
  const navigateTo = useNavigate();

  const analyticsPage = useContext(AnalyticsPageContext);

  const clickVendor = vendor => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsPage,
      event_name: [events.interaction],
      link_id: `${pagePrefix}: ${category.name}: ${vendor.name}`,
    });
    navigateTo(ROUTES.VENDOR.get(vendor.id));
  };

  const numCardsToShow = config.frontend.general.catalogue.maxNumCardsToShow;
  const vendorsToShow = category?.vendors.slice(0, (numCardsToShow)) ?? Array.from({ length: numCardsToShow });

  // Lieb doesn't want to show an uneven number of cards unless there is only one card
  const mustMakeVendorsEven = vendorsToShow.length !== 1 && vendorsToShow.length % 2 === 1;

  if (mustMakeVendorsEven) {
    vendorsToShow.pop();
  }

  return (
    <section className={ `category-card v-container${category ? '' : ' empty'}` }>
      <h3 className={ description ? '' : 'skeleton-loader' }>{description}</h3>
      <ul>
        {
          category
            ? vendorsToShow.map(vendor => (
              <button
                key={ vendor.id }
                onClick={ () => vendor.vouchers.length > 0 && clickVendor(vendor) }
              ><VendorCard vendor={ vendor } />
              </button>
            ))
            : vendorsToShow.map((_, index) => (
              <div key={ index }>
                <VendorCard />
              </div>
            ))
        }
      </ul>
    </section>
  );
}

CategoryCard.propTypes = {
  category: PropTypeShapes.category,
  description: PropTypes.string,
};
