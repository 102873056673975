import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../services/context/ConfigContext.js';
import { getConfig } from '../services/hooks/useApis.js';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../services/Constants/GlobalRoutes.jsx';
import MaintenancePage from '../pages/Maintenance/MaintenancePage.jsx';
import { UpdateMaintenanceMessageContext } from '../services/context/MaintenanceContext.js';
import { addBreadcrumb, captureException } from '@sentry/react';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The ConfigController Component
 */
export default function ConfigController({ slot }) {
  const [config, setConfig] = useState(null);
  const [attempts, setAttempts] = useState(0);
  const setMaintenanceMessage = useContext(UpdateMaintenanceMessageContext);
  const maxAttempts = 5;

  const navigateTo = useNavigate();

  useEffect(() => {
    if (!config && attempts < maxAttempts) {
      getConfig()
        .then(setConfig)
        .then(() => setAttempts(0))
        .catch(() => {
          setAttempts(prevState => prevState + 1);
        });
    }
  }, [
    config,
    attempts,
  ]);

  useEffect(() => {
    if (config?.frontend.general.maintenanceWarning.flows.load) {
      setMaintenanceMessage(config.frontend.general.maintenanceWarning.message);
      navigateTo(ROUTES.MAINTENANCE, { replace: true });
    }
  }, [config]);

  useEffect(() => {
    if (attempts >= maxAttempts && !config) {
      addBreadcrumb({
        category: 'config fetch attempts',
        message: `${attempts} attempts`,
      });
      captureException(new Error('Failed to fetch config'));
      setMaintenanceMessage('We have run into a technical problem. Please try again in a few seconds');
    }
  }, [
    attempts,
    config,
  ]);

  if (!config) {
    if (attempts >= maxAttempts) return (<MaintenancePage />);
    return null;
  }

  return (
    <ConfigContext.Provider value={ config }>
      {slot}
    </ConfigContext.Provider>
  );
}

ConfigController.propTypes = { slot: PropTypes.element };
