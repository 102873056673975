import useApis from './useApis.js';
import MiniAppMessageTypes from '../Constants/MiniAppMessageTypes.js';
import { useContext } from 'react';
import { ConfigContext } from '../context/ConfigContext.js';
import { UpdateMiniAppAuthHandlerContext } from '../context/MiniAppContext.js';
import { withSentrySpan } from '../Sentry.js';
import { captureMessage, startTransaction } from '@sentry/react';
import { SentryConfig } from '../Constants/Sentry.js';

export default () => {
  const config = useContext(ConfigContext);
  const { getAuth } = useApis();
  const setMiniAppAuthHandler = useContext(UpdateMiniAppAuthHandlerContext);

  const doAuth = async () => {
    const authHookTransaction = startTransaction(SentryConfig.auth.authHook.transaction);

    const tryWebAuth = async () => {
      try {
        return await withSentrySpan(
          authHookTransaction,
          SentryConfig.auth.authHook.spans.getAuthCall(config),
          () => getAuth()
            .then(value => {
              const { authCode, result: { resultStatus, resultCode, resultMsg } } = value;
              if (resultStatus === 'S' && authCode) return authCode;
              captureMessage('An error occurred attempting simulator web authentication', 'warning');
              throw new Error(`Auth Error: ${resultCode} ${resultMsg}`);
            }),
        );
      } finally { authHookTransaction.finish(); }
    };

    if (!window.my) return tryWebAuth();
    const auth = new Promise((resolve, reject) => {
      setMiniAppAuthHandler(() => message => {
        if (message.data.authCode) resolve(message.data.authCode);
        else if (message.simulator && config.external.vodapayGateway.v2.applyAuthCodeUrl) resolve(tryWebAuth());
        else reject(new Error('Auth Error: Unable to get miniapp authCode'));
      });
    });
    window.my.postMessage({ messageType: MiniAppMessageTypes.auth }, '*');
    return auth;
  };

  return { doAuth };
};
