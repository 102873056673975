import { Category, Vendor, Voucher } from './Constants/ObjectDefinitions.js';

/**
 * @param {Category[]} categories - list of all categories
 * @param {Voucher} voucher - voucher of vendor to find
 * @returns {Vendor|undefined} - voucher's vendor (undefined if unknown)
 */
export const getVoucherVendor = (categories, voucher) => {
  if (!(voucher && categories)) return;
  for (const category of categories) {
    for (const vendor of category?.vendors ?? []) {
      for (const vendorVoucher of vendor?.vouchers ?? []) {
        if (vendorVoucher.id === voucher.id) {
          return vendor;
        }
      }
    }
  }
};

/**
 * @param {Category[]} categories - list of all categories
 * @param {Vendor} vendor - vendor of category to find
 * @returns {Category|undefined} - vendor's category (undefined if unknown)
 */
export const getVendorCategory = (categories, vendor) => {
  if (!(vendor && categories)) return;
  for (const category of categories) {
    for (const categoryVendor of category?.vendors ?? []) {
      if (categoryVendor.id === vendor.id) {
        return category;
      }
    }
  }
};
