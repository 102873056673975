import React, { useContext } from 'react';
import './CouponCode.css';
import CopyIcon from '../../assets/CopyIcon.jsx';
import PropTypes from 'prop-types';
import PropTypeShapes from '../../services/Constants/PropTypeShapes.jsx';
import { UpdateSnackBarContext } from '../../services/context/SnackBarContext.js';
import { Gift } from '../../services/Constants/ObjectDefinitions.js';

const getExtraInfo = gift => {
  const description = gift.voucher?.options.buyNow.description;
  const canUseOnline = description?.includes('online');
  const canUseInStore = description?.includes('in-store') || description?.includes('instore'); // some vouchers fail to spell in-store correctly...
  let redeemLocation = null;
  if (canUseOnline && canUseInStore) redeemLocation = 'online or in-store';
  else if (canUseOnline) redeemLocation = 'online';
  else if (canUseInStore) redeemLocation = 'in-store';
  if (redeemLocation) return `This can be used when buying ${redeemLocation}`;
  return gift.name;
};

/**
 *
 * @param {object} props - the props of the component
 * @param {Gift} props.gift - the gift associated with the coupon
 * @param {boolean} props.noCopy - whether to allow copying of the code
 * @returns {JSX.Element} CouponCode Component
 */
export default function CouponCode({ gift, noCopy }) {
  const setSnackBar = useContext(UpdateSnackBarContext);
  return (
    <section className='coupon-code v-container-h'>
      <p>
        Voucher Code
      </p>

      <p className='extra-info'>
        {getExtraInfo(gift)}
      </p>

      <ul className='container-h'>
        {
          [...gift.redemptionCode].map((char, index) => (
            <p key={ index }>
              {char}
            </p>
          ))
        }
      </ul>

      {
        !noCopy
        && <button
          className='extra-info container-v'
          onClick={
            () => {
              navigator.clipboard.writeText(gift.redemptionCode);
              setSnackBar({
                icon: <CopyIcon />,
                body: <p>Voucher code copied</p>,
              });
            }
          }
        >
          <h4>
            Copy code
          </h4>

          <CopyIcon />
        </button>
      }
    </section>
  );
}

CouponCode.propTypes = {
  gift: PropTypeShapes.gift,
  noCopy: PropTypes.bool,
};
