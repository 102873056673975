import React, { useContext } from 'react';
import NavItem from '../NavItem.jsx';
import './NavCart.css';
import BasketIcon from '../../../assets/BasketIcon.jsx';
import PropTypeShapes from '../../../services/Constants/PropTypeShapes.jsx';
import { MoneyFormatterContext } from '../../../services/context/MoneyFormatterContext.js';
import { CartContext } from '../../../services/context/CartContext.js';
import ROUTES from '../../../services/Constants/GlobalRoutes.jsx';

/**
 * @returns {JSX.Element} NavCart Component
 */
export default function NavCart() {
  const cart = useContext(CartContext);
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const amount = cart.map(voucher => MoneyFormatter.getValue(voucher.cost))
    .reduce((prev, cur) => MoneyFormatter.add(prev, cur), MoneyFormatter.get(0));
  return (
    <NavItem
      icon={
        <>
          <p className='amount'>{MoneyFormatter.format(amount)}</p>
          <BasketIcon />
        </>
      }
      route={ ROUTES.CART }
      name='Cart'
    />
  );
}

NavCart.propTypes = { cart: PropTypeShapes.cart };
