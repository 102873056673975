import React, { useEffect, useRef } from 'react';
import './Voucher.css';
import PropTypes from 'prop-types';
import InfoIcon from '../../assets/InfoIcon.jsx';

const VoucherHeader = ({ iconUrl, title, addedValue }) => {
  const logoRef = useRef(null);
  useEffect(() => {
    logoRef.current?.style.setProperty('--image-url', `url(${iconUrl})`);
  }, [logoRef.current]);
  return (
    <header className='container'>
      {
        iconUrl
        && <div
          className='logo'
          ref={ logoRef }
        />
      }
      <section className='info v-container'>
        <h3>{title}</h3>
        <div className='added-value container-v'>
          <p>{addedValue}</p>
          {
            !iconUrl
            && <InfoIcon />
          }
        </div>
      </section>
    </header>
  );
};

VoucherHeader.propTypes = {
  iconUrl: PropTypes.string,
  title: PropTypes.element,
  addedValue: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
};

/**
 * @param {object} props - The props for the component
 * @param {string} props.iconUrl - The URL for the logo
 * @param {JSX.Element} props.title - The Title element
 * @param {JSX.Element | string} props.addedValue - Element for added value
 * @param {JSX.Element} props.actionItem - Action item element
 * @param {Function} props.onClick - Function for clicking on component
 * @returns {JSX.Element} Voucher Component
 */
export default function Voucher({ iconUrl, title, addedValue, actionItem, onClick }) {
  const empty = (iconUrl ?? title ?? addedValue ?? actionItem ?? onClick) === undefined;
  return (
    <div className={ `voucher container-v${empty ? ' empty skeleton-loader' : ''}` }>
      {
        onClick ? <button
          onClick={ onClick }
          className='on-click'
        >
          <VoucherHeader
            iconUrl={ iconUrl }
            title={ title }
            addedValue={ addedValue }
          />
        </button>
          : <div className='on-click'>
            <VoucherHeader
              iconUrl={ iconUrl }
              title={ title }
              addedValue={ addedValue }
            />
          </div>
      }
      {
        actionItem
        && <div className='action-item'>{actionItem}</div>
      }
      {
        empty
        && <div className='action-item'>
          <div />
        </div>
      }
    </div>
  );
}

Voucher.propTypes = {
  onClick: PropTypes.func,
  iconUrl: PropTypes.string,
  title: PropTypes.element,
  addedValue: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  actionItem: PropTypes.element,
};
