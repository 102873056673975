import React, { useContext, useEffect } from 'react';
import CartDetails from '../../components/Cart/Details/CartDetails.jsx';
import CartValue from '../../components/Cart/Value/CartValue.jsx';
import CartPayment from '../../components/Cart/Payment/CartPayment.jsx';
import { CartContext } from '../../services/context/CartContext.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';

/**
 * @returns {JSX.Element} CartPage component
 */
export default function CartPage() {
  const cart = useContext(CartContext);
  const MoneyFormatter = useContext(MoneyFormatterContext);

  const analyticsName = `${pagePrefix}: my cart`;
  useEffect(() => {
    window.utag?.view({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [
        events.pageView,
        events.cartView,
      ],
      transaction_subtotal: cart.map(voucher => MoneyFormatter.getValue(voucher.cost))
        .reduce((acc, cur) => MoneyFormatter.add(acc, cur), MoneyFormatter.get(0))
        .amount,
      cart_quantity: cart.length,
    });
  }, []);

  return (
    <AnalyticsPageContext.Provider value={ analyticsName }>
      <CartDetails />
      <CartValue />
      {
        cart.length > 0
        && <CartPayment />
      }
    </AnalyticsPageContext.Provider>
  );
}
