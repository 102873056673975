const ROUTES = {
  ADVANCE_SUMMARY: '/Advance/Summary',
  HOME: '/',
  CATEGORY: { get: id => (id ? `/Category/${id}` : '/Category/:id') },
  CART: '/Cart',
  CROSS_SELL: '/Cross Sell',
  ERROR: '/Error',
  FAILURE: '/Failure',
  VENDOR: { get: id => (id ? `/Vendor/${id}` : '/Vendor/:id') },
  MY_VOUCHERS: '/My Vouchers',
  SHARE: '/Share',
  SUCCESS: '/Success',
  MANAGE_ADVANCE: '/Manage',
  PAYMENT_SELECT: '/Pay',
  SUMMARY: '/Summary',
  NOT_FOUND: '/404',
  MAINTENANCE: '/Maintenance',
  VOUCHER_INFO: '/My Vouchers/Info',
};

export default ROUTES;
