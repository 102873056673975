import { stringToDays } from './Dates.js';
import { Eligibility, Voucher, AdvanceOption } from './Constants/ObjectDefinitions.js';

/**
 * @param {Eligibility} [eligibility] - user's eligibility
 * @param {Voucher} voucher - voucher to create filter for
 * @param {{getValue: Function}} MoneyFormatter - money formatter
 * @returns {function(AdvanceOption): boolean} - is advance option filter
 */
export const createIsAdvanceOptionFilter = (eligibility, voucher, MoneyFormatter) => option => Boolean(eligibility?.advanceOffers?.some(offer => (
  offer.id === option.offerId
  && Math.abs(MoneyFormatter.getValue(voucher.cost).amount - offer.amount) < 0.001 // saving from floating point headaches
  && stringToDays(option.term) === offer.term)));
