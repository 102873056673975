export const getAdvanceDetails = advanceString => {
  const [
    type,
    partialRedemption,
    validity,
    latePayment,
    redemptionLocation,
  ] = advanceString.split('. ');
  return {
    type,
    partialRedemption,
    validity,
    latePayment,
    redemptionLocation,
  };
};

export const replaceURLPathParams = (url, pathParams) => Object.entries(pathParams ?? {})
  .reduce((accumulator, [key, value]) => accumulator
    .replaceAll(`{${key}}`, `${value}`), url);

export const stringMasker = (text, shownCount) => `${'*'.repeat(text.length - shownCount)}${text.slice(-shownCount)}`;

export const msisdnMasker = text => text.replace(/(?<prefix>\d{1,3}-\d)\d{4,7}(?<suffix>\d{3})/g, '$1***$2');

export default {
  getAdvanceDetails,
  replaceURLPathParams,
};
