import React from 'react';
import './MaintenanceMan.css';
import SpaceMan from '../../assets/SpaceMan.jsx';

/**
 * @returns {JSX.Element} MaintenanceMan Component
 */
export default function MaintenanceMan() {
  return (
    <div className='maintenance-man'>
      <SpaceMan />
    </div>
  );
}
